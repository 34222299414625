import * as Sentry from "@sentry/browser";

import { isOutlookUser } from "../lib/outlookFunctions";
import { formatEmail, isSameEmail } from "../lib/stringFunctions";
import { BACKEND_SETTINGS_NAMES } from "../lib/vimcalVariables";
import { getCalendarEmail, getCalendarIsPrimary, getCalendarObject, getCalendarUserCalendarID } from "./calendarAccessors";
import { MAESTRO_DELEGATION_TYPES } from "./globalMaestroVariables";
import { getMagicLinkEmail, getUserConnectedAccountDetails } from "./maestro/maestroAccessors";
import { getSettingValue } from "./settingsAccessors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { handleError, sendBreadcrumbToSentry } from "./commonUsefulFunctions";
import { getMasterAccountEmail, getMatchingUserFromAllUsers, getUserEmail, getUserToken } from "../lib/userFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { getCalendarCountFromAllCalendars, getCalendarUserEmail, getMatchingCalendarsForUser } from "../lib/calendarFunctions";
import { removeDuplicatesFromArray } from "../lib/arrayFunctions";
import { getMasterAccountFromStore } from "../lib/zustandFunctions";
import { useMagicLink } from "./stores/magicLinkStore";

export const LINKABLE_TYPES = {
  AVAILABILITY_LINK: "AvailabilityLink",
  GROUP_VOTE_LINK: "GroupVoteLink",
  GROUP_SPREADSHEET_LINK: "GroupSpreadsheetLink",
};

/*********************/
/* Maestro Functions */
/*********************/

/**
 * @param {MasterAccount | Record<string, never> | null | undefined} masterAccount
 */
export function isUserMaestroUser(masterAccount) {
  if (isEmptyObjectOrFalsey(masterAccount) || !masterAccount.scheduling_for_others) {
    return false;
  }
  return masterAccount.scheduling_for_others;
}

export function isUserOutlookMaestro({  masterAccount, user }) {
  return isUserMaestroUser(masterAccount) && isOutlookUser(user);
}

/*****************************/
/* User Delegation Functions */
/*****************************/

// delegated users can either have limited or full access
// limited = the EA had write access and converted a calendar of an exec to that separate delegated user. they did not login with email/password
// full = they logged in as that exec with the exec's google email/password
// majority case is limited
export function isUserLimitedAccess(user) {
  if (isEmptyObjectOrFalsey(user) || !user.delegation) {
    return false;
  }

  return user.delegation === MAESTRO_DELEGATION_TYPES.LIMITED;
}

export function isUserDelegatedUser(user) {
  if (isEmptyObjectOrFalsey(user) || !user.delegation) {
    return false;
  }
  return !!user.delegation;
}

export function isUserMagicLinkUser({ user }) {
  try {
    const localConnectedAccountTokens = getLocalConnectedAccountTokens();
    const isTokenStoredLocally = !!localConnectedAccountTokens[getUserEmail(user)];
    if (
      (isEmptyObjectOrFalsey(user) || !user.connected_account_token) &&
      !isTokenStoredLocally
    ) {
      return false;
    }
    return true;
  } catch(e) {
    handleError(e);
    return false;
  }
}

export function isMaestroUserOnDelegatedAccount({ masterAccount, user }) {
  return isUserMaestroUser(masterAccount) && isUserDelegatedUser(user);
}

export function isUserExecutiveUser({ user }) {
  return isUserDelegatedUser(user) || isUserMagicLinkUser({ user });
}

/**
 * @param {Object} options
 * @param {User[]} options.allLoggedInUsers
 * @returns {User[]}
 */
export function getAllExecutives({ allLoggedInUsers }) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return [];
  }

  return allLoggedInUsers.filter((user) => isUserExecutiveUser({ user }));
}

/*****************************/
/* Zoom Delegation Functions */
/*****************************/

export function isUserBeingScheduledFor({user, schedulers, masterAccount}) {
  if (isEmptyObjectOrFalsey(user) || !getUserEmail(user) || !schedulers || !isUserMaestroUser(masterAccount)) {
    return false;
  }

  return !!getZoomSchedulerUserID({user, schedulers});
}

export function getDelegatedZoomPMI({ user, schedulers }) {
  if (isEmptyObjectOrFalsey(user) || isEmptyObjectOrFalsey(schedulers)) {
    return;
  }

  /* We call this function after checking isUserBeingScheduledFor so this should always have a value */
  const matchingUserId = getZoomSchedulerUserID({ user, schedulers });
  return schedulers[matchingUserId]?.schedulers?.find((scheduler) => isSameEmail(scheduler.email, user.email))?.pmi;
}

function getZoomSchedulerUserID({user, schedulers}) {
  try {
    const userEmail = formatEmail(user?.email);
    if (!userEmail || isEmptyObjectOrFalsey(schedulers)) {
      return null;
    }
    // Extract all matching schedulers with the given user email
    const matchingSchedulers = Object.keys(schedulers).filter((key) => {
      const schedulerGroup = schedulers?.[key]?.schedulers;
      if (!schedulerGroup) {
        return false;
      }

      const allEmailsForKey = schedulerGroup.map((scheduler) => getObjectEmail(scheduler));
      return allEmailsForKey.includes(userEmail);
    });

    if (isEmptyArrayOrFalsey(matchingSchedulers)) {
      return null;
    }

    // Find the scheduler with the latest updated_at time
    const latestSchedulerKey = matchingSchedulers.reduce((latestKey, currentKey) => {
      const latestUpdatedAt = schedulers[latestKey]?.updated_at;
      const currentUpdatedAt = schedulers[currentKey]?.updated_at;

      return latestUpdatedAt > currentUpdatedAt ? latestKey : currentKey;
    });

    return latestSchedulerKey;
  } catch (error) {
    return null;
  }
}

export function getZoomSchedulerID({ user, schedulers }) {
  const userID = getZoomSchedulerUserID({user, schedulers});
  if (!userID) {
    return {};
  }

  return {user_id: userID};
}

/************************/
/* Magic Link Functions */
/************************/

export function doesMagicLinkExist({ magicLink }) {
  return !isEmptyObjectOrFalsey(magicLink);
}

export function getPrimaryUserCalendarIDByMagicLinkEmail({ magicLink, magicLinkAllCalendars }) {
  if (isEmptyObjectOrFalsey(magicLink) || isEmptyArrayOrFalsey(magicLinkAllCalendars)) {
    return "";
  }

  /* Calendars belonging to the email invited in magic link */
  const calendars = magicLinkAllCalendars[getMagicLinkEmail({ magicLink })];
  const primaryCalendar = calendars?.find(calendar => getCalendarIsPrimary(calendar));

  return getCalendarUserCalendarID(primaryCalendar) ?? "";
}

export function getDelegatedUserAuthenticatedUser(user) {
  return user?.authenticated_user;
}

/**
 * @param {Object} options
 * @param {User | null | undefined} options.user
 * @returns {{ firstName: string, lastName: string, fullName: string, userName: string }}
 */
export function getConnectedAccountUserName({ user }) {
  const connectedAccountDetails = getUserConnectedAccountDetails({ user });

  /* We should NEVER enter here */
  if (isEmptyArrayOrFalsey(connectedAccountDetails)) {
    sendBreadcrumbToSentry({
      category: "", // What should this be?
      message: "[Username] Missing connected account details.",
      data: {
        backendSettingName: BACKEND_SETTINGS_NAMES.USERNAME,
        user,
      },
      level: Sentry.Severity.Warning,
    });

    return {
      firstName: "",
      lastName: "",
      fullName: "",
      userName: "",
    };
  }

  const firstName = user?.first_name ?? "";
  const lastName = user?.last_name ?? "";
  // User user.full_name else check if we have first and last
  // If we do, then use `${firstName} ${lastName}` instead
  const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : (user?.full_name ?? "");

  return {
    firstName: firstName,
    lastName: lastName,
    fullName: fullName,
    userName: getSettingValue({
      backendSettingName: BACKEND_SETTINGS_NAMES.USERNAME,
      user,
    }) ?? "",
  };
}

export function groupByMyAccountAndExecs({ allLoggedInUsers }) {
  let myAccounts = [];
  let execAccounts = [];
  allLoggedInUsers.forEach((user) => {
    /* Proxy users */
    if (isUserLimitedAccess(user)) {
      execAccounts = execAccounts.concat(user);
      return;
    }

    const connectedAccountDetails = getUserConnectedAccountDetails({ user });
    /* User is from EA's own master account */
    if (isEmptyObjectOrFalsey(connectedAccountDetails)) {
      myAccounts = myAccounts.concat(user);
      return;
    }
    execAccounts = execAccounts.concat(user);
  });
  return {
    myAccounts,
    execAccounts,
  };
}

export function groupAndSortAllLoggedInUsersByExecutive({ allLoggedInUsers, currentUser, masterAccount }) {
  const masterAccountEmail = getMasterAccountEmail({ masterAccount });
  let executivesWithUsers = {
    [masterAccountEmail]: {
      masterAccount,
      users: [],
    },
  };

  allLoggedInUsers.forEach((user) => {
    /* Proxy users */
    if (isUserLimitedAccess(user)) {
      const userEmail = getUserEmail(user);
      executivesWithUsers[userEmail] = {
        /* Fake a master account */
        masterAccount: {
          first_name: user?.first_name ?? "",
          last_name: user?.last_name ?? "",
          full_name: user?.full_name ?? "",
          username: user?.username ?? "",
          stripe_email: userEmail,
          internal_profile_photo_url: user?.internal_profile_photo_url,
        },
        users: [user],
      };
      return;
    }

    const connectedAccountDetails = getUserConnectedAccountDetails({ user });
    /* User is from EA's own master account */
    if (isEmptyObjectOrFalsey(connectedAccountDetails)) {
      executivesWithUsers[masterAccountEmail]?.users?.push(user);
      return;
    }

    /* User belongs to an Executive */
    const executiveMasterAccount = connectedAccountDetails?.master_account;
    const executiveMasterAccountEmail = getMasterAccountEmail({ masterAccount: executiveMasterAccount });

    /* Master account has not been added to object */
    if (Object.keys(executivesWithUsers).indexOf(executiveMasterAccountEmail) === -1) {
      /* Add the base values to the array */
      executivesWithUsers[executiveMasterAccountEmail] = {
        masterAccount: executiveMasterAccount,
        users: [user],
      };
      return;
    }

    /* Master account has already been added - just need to push to user array */
    executivesWithUsers[executiveMasterAccountEmail]?.users?.push(user);
  });

  /* Sort user arrays */
  Object.values(executivesWithUsers).forEach(executiveWithUser => {
    const { users } = executiveWithUser;

    users.sort((userA, userB) => {
      if (getUserToken(userA) === getUserToken(currentUser)) {
        return -1;
      }

      if (getUserToken(userB) === getUserToken(currentUser)) {
        return 1;
      }

      return getUserEmail(userA) < getUserEmail(userB) ? -1 : 1;
    });
  });

  /* Sort executives */
  return Object.values(executivesWithUsers).sort((executiveHashOne, executiveHashTwo) => {
    /* Current user is in executiveHashOne */
    if (executiveHashOne?.users?.find(user => getUserToken(user) === getUserToken(currentUser))) {
      return -1;
    }

    /* Current user is in executiveHashTwo */
    if (executiveHashTwo?.users?.find(user => getUserToken(user) === getUserToken(currentUser))) {
      return 1;
    }

    /* Sort alphabetically */
    const executiveHashOneName = getMasterAccountEmail({ masterAccount: executiveHashOne?.masterAccount });
    const executiveHashTwoName = getMasterAccountEmail({ masterAccount: executiveHashTwo?.masterAccount });
    return executiveHashOneName < executiveHashTwoName ? -1 : 1;
  });
}

export function isCalendarExecutiveCalendar({ calendar, allLoggedInUsers }) {
  try {
    if (isEmptyArrayOrFalsey(allLoggedInUsers) || isEmptyObjectOrFalsey(calendar)) {
      return false;
    }
    const execUsers = getAllExecutives({ allLoggedInUsers });
    return execUsers.some(user => {
      if (isUserMagicLinkUser({ user })) {
        // if magic link user -> we only want to mark the calendar as executive if it's the primary calendar
        return getCalendarIsPrimary(calendar) && isSameEmail(getUserEmail(user), getCalendarEmail(calendar));
      }
      return isSameEmail(getUserEmail(user), getCalendarEmail(calendar));
    });
  } catch (error) {
    handleError(error);
    return false;
  }
}

export function getMatchingExecutiveCalendar({ allCalendars, user }) {
  try {
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return null;
    }
    if (!isUserDelegatedUser(user)) {
      return null;
    }
    const filteredCalendars = Object.values(allCalendars).filter(calendar => !isSameEmail(getUserEmail(user), getCalendarUserEmail(calendar)));

    // pass in single user since we're trying to find the calendar for that particular user
    return filteredCalendars.find(calendar => isCalendarExecutiveCalendar({ calendar, allLoggedInUsers: [user] }));
  } catch (error) {
    return null;
  }
}

export function shouldHideDelegatedUserCalendar({
  calendar,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
}) {
  if (!isUserMaestroUser(masterAccount || getMasterAccountFromStore())) {
    return false;
  }
  if (isEmptyObjectOrFalsey(allCalendars)) {
    return false;
  }
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return false;
  }
  const matchingUser = getMatchingUserFromAllUsers({
    allUsers: allLoggedInUsers,
    userEmail: getCalendarUserEmail(calendar),
  });
  return shouldHideDelegatedUser({ user: matchingUser, allCalendars });
}

export function shouldHideDelegatedUser({ user, allCalendars }) {
  try {
    if (isUserMagicLinkUser({ user })) {
      return false;
    }
    if (!isUserDelegatedUser(user)) {
      return false;
    }
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return false;
    }
    const filteredCalendars = getMatchingCalendarsForUser({
      allCalendars,
      user,
      userEmail: getUserEmail(user),
    });
    if (getCalendarCountFromAllCalendars(filteredCalendars) > 1) {
      return false;
    }
    const matchingExecutiveCalendar = getMatchingExecutiveCalendar({
      allCalendars,
      user,
    });
    if (matchingExecutiveCalendar) {
      // if there's a matching executive calendar, then we should hide the delegated user calendar
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
    return false;
  }
}

/**
 * Returns an array of userCalendarIDs that are hidden delegated user calendars
 * @param {Object} options
 * @param {string[]?} options.userCalendarIDs - Array of calendar IDs to check
 * @param {AllCalendarsState["allCalendars"]} options.allCalendars - Map of all available calendars
 * @param {(User[] | null | undefined)} options.allLoggedInUsers - Array of all logged in users
 * @param {(MasterAccountState["masterAccount"] | null | undefined)} options.masterAccount - The master account object
 * @returns {string[]} Array of calendar IDs that should be hidden
 */
function getHiddenDelegatedUserCalendarIDs({
  userCalendarIDs,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
}) {
  if (isEmptyArrayOrFalsey(userCalendarIDs)
    || isEmptyObjectOrFalsey(allCalendars)
    || isEmptyArrayOrFalsey(allLoggedInUsers)
  ) {
    return [];
  }
  return userCalendarIDs.filter(userCalendarID => {
    const calendar = allCalendars[userCalendarID];
    if (!calendar) {
      return false;
    }
    if (shouldHideDelegatedUserCalendar({
      calendar,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    })) {
      return true;
    }
    return false;
  });
}

/**
 * Swap out userCalendarIDs that belong to delegated users that should be hidden with matching executive calendars
 * @param {Object} options
 * @param {string[]?} options.userCalendarIDs - Array of calendar IDs to process
 * @param {Object?} options.allCalendars - Map of all available calendars
 * @param {(User[] | null | undefined)} options.allLoggedInUsers - Array of all logged in users
 * @param {(MasterAccount | Record<string, never> | null | undefined)} options.masterAccount - The master account object
 * @returns {string[]} Updated array of calendar IDs with delegated calendars replaced by executive ones
 */
export function switchOutDelegateUserCalendarsWithMatchingExecutiveCalendars({
  userCalendarIDs,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
}) {
  try {
    if (isEmptyArrayOrFalsey(userCalendarIDs)) {
      return [];
    }
    if (isEmptyArrayOrFalsey(allLoggedInUsers) || isEmptyObjectOrFalsey(allCalendars)) {
      // no point in continuing if allLoggedInUsers or allCalendars are empty
      return userCalendarIDs;
    }
    // for backwards compatibility, filter out out delegate user calendars
    // then add the appropriate executive calendar
    // return upcomingUserCalendarIDs.filter(id => !isCalendarExecutiveCalendar({calendar: allCalendars[id], allCalendars}));
    const hiddenDelegatedUserCalendarIDs = getHiddenDelegatedUserCalendarIDs({
      userCalendarIDs,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
    if (isEmptyArrayOrFalsey(hiddenDelegatedUserCalendarIDs)) {
      return userCalendarIDs;
    }
    const hiddenDelegatedUserCalendars = hiddenDelegatedUserCalendarIDs
      .map(id => allCalendars[id])
      .filter(calendar => !!calendar);
    const matchingExecutiveCalendars = hiddenDelegatedUserCalendars.map(calendar => {
      const matchingUser = getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail: getCalendarUserEmail(calendar),
      });
      return getMatchingExecutiveCalendar({
        allCalendars,
        user: matchingUser,
      });
    }).filter(calendar => !!calendar);

    return removeDuplicatesFromArray(userCalendarIDs
      .filter(id => !hiddenDelegatedUserCalendarIDs.includes(id))
      .concat(matchingExecutiveCalendars.map(calendar => getCalendarUserCalendarID(calendar))),
    );
  } catch (error) {
    return userCalendarIDs;
  }
}

export function getMatchingExecOrNormalUserFromCalendar({ calendar, allLoggedInUsers, masterAccount }) {
  if (isCalendarExecutiveCalendar({ calendar, allLoggedInUsers }) && isUserMaestroUser(masterAccount)) {
    const matchingExecUser = getMatchingExecutiveUserFromCalendar({ calendar, allLoggedInUsers });
    if (matchingExecUser) {
      return matchingExecUser;
    }
  }
  return getMatchingUserFromAllUsers({ allUsers: allLoggedInUsers, userEmail: getCalendarUserEmail(calendar) });
}

export function getMatchingExecutiveUserFromCalendar({ calendar, allLoggedInUsers }) {
  try {
    if (isEmptyObjectOrFalsey(calendar) || isEmptyArrayOrFalsey(allLoggedInUsers)) {
      return null;
    }
    const calendarEmail = getCalendarEmail(calendar);
    return allLoggedInUsers.find(user => isSameEmail(getUserEmail(user), calendarEmail));
  } catch (error) {
    handleError(error);
    return null;
  }
}

export function switchOutDelegateBlockedCalendarsWithMatchingExecutiveCalendars({
  blockedUserAndCalendars,
  masterAccount,
  user,
  allCalendars,
  allLoggedInUsers,
}) {
  try {
    if (isEmptyArrayOrFalsey(blockedUserAndCalendars)) {
      return blockedUserAndCalendars;
    }
    if (!isUserMaestroUser(masterAccount)) {
      return blockedUserAndCalendars;
    }
    if (!shouldHideDelegatedUser({user, allCalendars})) {
      return blockedUserAndCalendars;
    }
    if (isEmptyArrayOrFalsey(blockedUserAndCalendars) || isEmptyObjectOrFalsey(allCalendars)) {
      return blockedUserAndCalendars;
    }
    return blockedUserAndCalendars.map(userAndCalendarObject => {
      const { calendars } = userAndCalendarObject;
      if (isEmptyArrayOrFalsey(calendars)) {
        return userAndCalendarObject;
      }
      const updatedCalendarUserCalendarIDs = switchOutDelegateUserCalendarsWithMatchingExecutiveCalendars({
        userCalendarIDs: calendars.map(calendar => calendar?.user_calendar_id), // do not use getCalendarUserCalendarID because there's no extra .calendar layer
        allCalendars,
        allLoggedInUsers,
        masterAccount,
      });
      const updatedCalendars = updatedCalendarUserCalendarIDs
        .map(userCalendarID => getCalendarObject(allCalendars[userCalendarID]))
        .filter(calendar => !!calendar);
      if (isEmptyArrayOrFalsey(updatedCalendars)) {
        return userAndCalendarObject;
      }
      return {
        ...userAndCalendarObject,
        calendars: updatedCalendars,
      };
    });
  } catch (error) {
    handleError(error);
    return blockedUserAndCalendars;
  }
}

export function getLocalConnectedAccountTokens() {
  return useMagicLink?.getState()?.connectedAccountTokens || {};
}
