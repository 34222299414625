import {
  getCalendarColorIDV1,
  getCalendarEmail,
} from "../services/calendarAccessors";
import {
  getEventColorID,
  getEventEtag,
  getEventExtendedProperties,
  getEventUserCalendarID,
} from "../services/eventResourceAccessors";
import { getEventColorHex } from "../services/eventResourceAccessors";
import type {
  CombinedTags,
  Tags,
} from "../components/tags/tagsVariables";
import googleColors from "../services/googleColors";
import { safeJSONParse } from "./jsonFunctions";
import { SPECIAL_TAGS_TYPE } from "./vimcalVariables";
import { isUserDelegatedUser, isUserExecutiveUser, isUserMaestroUser, isUserMagicLinkUser } from "../services/maestroFunctions";
import {
  getCurrentPainterColors,
  getMasterAccountUser,
  getMatchingUserFromAllUsers,
  getUserEmail,
  getUserSmartTags,
} from "./userFunctions";
import { isEmptyArray, isNotEmptyArry } from "./arrayFunctions";
import { doesGoogleEventHaveDefaultColor } from "./googleFunctions";
import { getEventCalendarProviderID, isGoogleEvent } from "./eventFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getCalendarUserEmail, getMatchingUserFromEvent } from "./calendarFunctions";
import { isSameEmail } from "./stringFunctions";
import { isLocal } from "../services/devFunctions";
import { isSmartTag, PAINTER_MATCH_ANY } from "./painterFunctions";
import { AllCalendarsState, MasterAccountState } from "../services/stores/SharedAccountData";
import { getEmailBasedOnCalendarId } from "../services/commonUsefulFunctions";

let _parsedEtagCache = {};

export const TAG_USER_TOKENS_KEY = "user_tokens";

export const displayLabelFromTags = ({ tags }: { tags: Tags }) => {
  /* Default to "Label" */
  let label = "Tag";

  /* Change label if we have paint colors */
  if (tags?.length === 1) {
    label = tags[0].name;
  }

  if (tags?.length > 1) {
    label = `${tags.length} tags`;
  }

  if (isTagNameSpecialTag(label)) {
    return convertSpecialTagsToHumanReadable(label);
  }

  return label;
};

function isTagNameSpecialTag(tagName) {
  return Object.values(SPECIAL_TAGS_TYPE).includes(tagName);
}

export function isTagSpecialTag(tag) {
  return isTagNameSpecialTag(getTagName(tag));
}

function convertSpecialTagsToHumanReadable(label) {
  if (!label) {
    return "(No title)";
  }
  switch (label) {
    case SPECIAL_TAGS_TYPE.SOLO:
      return "Solo";
    case SPECIAL_TAGS_TYPE.EXTERNAL:
      return "External";
    case SPECIAL_TAGS_TYPE.INTERNAL:
      return "Internal";
    default:
      return label.split("_")?.[1] || label;
  }
}

export function getTagsStringFromEvent(event) {
  return getEventExtendedProperties(event)?.private?.tags;
}

function getTagsCacheKey(event) {
  // not can not use etag since if we change extended properties, etag does not change
  // updated_at also does not get updated
  return `${getEventEtag(event)}-${getTagsStringFromEvent(event) ?? ""}`;
}

export const getTagsFromEvent = (event) => {
  const jsonTags = getTagsStringFromEvent(event);

  if (jsonTags) {
    const cacheKey = getTagsCacheKey(event);
    if (_parsedEtagCache[cacheKey]) {
      return _parsedEtagCache[cacheKey];
    }
    try {
      const parsedJson = JSON.parse(jsonTags);
      _parsedEtagCache[cacheKey] = parsedJson;
      return parsedJson;
    } catch (e) {
      // invalid json here (google could have silently cut the tags string early
      // Find the last complete object in the string.
      const lastCompleteObjectIndex = jsonTags.lastIndexOf("}");

      // Slice the string up to that point, and add the closing array bracket.
      const validJson = jsonTags.slice(0, lastCompleteObjectIndex + 1) + "]";

      // Now it should be valid JSON, so you can parse it.
      return safeJSONParse(validJson) ?? [];
    }
  }

  return [];
};

export const getTagColorFilteringForTransparent = (tag: Tag) => {
  const color = tag?.color;
  if (color === "transparent") {
    return null;
  }
  return color;
};

export const getTagColor = (tag: Tag) => {
  return tag?.color;
};

export const getTagColorId = (tag: Tag) => {
  return tag?.color_id;
};

export const getTagName = (tag: Tag) => {
  return tag?.name;
};

export const getTagId = (tag: Tag) => {
  return tag?.user_smart_tag_id;
};

export const calculateEventColorId = ({
  allCalendars,
  event,
  tags,
  previousTags,
}) => {
  const userCalendarId = getEventUserCalendarID(event);
  const eventColorId = getEventColorID(event);
  const calendarColorId = getCalendarColorIDV1(allCalendars[userCalendarId]);

  /* IDs aren't one to one for event and calendar */
  const eventColorHex = googleColors.primaryEventsColors[eventColorId]?.color;
  const calendarColorHex = googleColors.calendar[calendarColorId]?.background;

  /* Use event color if event has a set color that doesn't match calendar */
  /* Explicitly check for undefined and null values */
  if (
    previousTags?.length === 1 &&
    tags?.length === 0 &&
    previousTags[0].color === eventColorHex
  ) {
    // delete last one and revert to event color
    return "0";
  }

  if (eventColorHex && eventColorHex !== calendarColorHex) {
    return eventColorId;
  } else {
    /* Event doesn't have a set color */
    /* Use first paint color if it exists */
    if (tags?.length > 0 && !isTransparentTag(tags[0])) {
      return getTagColorId(tags[0]);
    }

    /* Return null to prevent updating colorId */
    return null;
  }
};

export const getEventSmartTags = ({ event }) => {
  if (isEmptyObjectOrFalsey(event) || isEmptyArray(event?.tags)) {
    return null;
  }

  // Return the array of tags on an event
  return event.tags;
};

export function getSmartTagString(event) {
  const smartTags = getEventSmartTags({ event });
  if (isEmptyArray(smartTags)) {
    return;
  }

  return smartTags.map((tag) => getTagColor(tag)).join(", ");
}

export const getEventTagColor = ({
  event,
  defaultColor,
  user,
  currentUser,
  allLoggedInUsers,
  masterAccount,
  allCalendars,
}) => {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  // Get color of first tag in event
  const allTags = getAllTagsFromEvent({event, user, currentUser, allLoggedInUsers, masterAccount, allCalendars});
  if (!isEmptyArrayOrFalsey(allTags) && !isTransparentTag(allTags[0])) {
    // tags array has to exist and the first tag (sorted after priority tag is not a clear tag)
    return getTagColor(allTags[0]);
  }

  // Default to event color
  return defaultColor ?? getEventColorHex(event);
};

function isManualTag(tag) {
  try {
    return !tag?.isSmartTag;
  } catch (e) {
    return false;
  }
}

export const generateMergedTagsAndSmartTags = ({
  tags,
  smartTags,
}: {
  tags: Tag[];
  smartTags: SmartTag[];
}): CombinedTags[] => {
  if (isEmptyArray(tags) && isEmptyArray(smartTags)) {
    return [];
  }

  // For filtering normal tags against smart tags
  let userSmartTagIds: string[] = [];
  const filteredSmartTagObjects =
    smartTags?.map((tag) => {
      userSmartTagIds.push(tag.user_smart_tag_id);

      return {
        color: tag.color,
        color_id: tag.color_id,
        isSmartTag: true,
        name: tag.name,
        user_smart_tag_id: tag.user_smart_tag_id,
        is_prioritized: tag.is_prioritized ?? false,
      };
    }) ?? [];
  const filteredSettingsObjects =
    tags.filter((tag) => !userSmartTagIds.includes(tag.user_smart_tag_id)) ??
    [];

  // Return an array of unique CombinedTags type
  // We want smart tags to be marked so that user can't set and unset them on events
  const unsortedTags = [...filteredSettingsObjects, ...filteredSmartTagObjects];
  const sortedTags = unsortedTags.sort((a, b) => {
    const aIsPriority = isPriorityTag(a);
    const bIsPriority = isPriorityTag(b);
    if (aIsPriority && !bIsPriority) {
      return -1; // a comes first
    }
    if (!aIsPriority && bIsPriority) {
      return 1; // b comes first
    }
    if (aIsPriority && bIsPriority) {
      if (isManualTag(a) && !isManualTag(b)) {
        return -1; // a comes first
      }
      if (!isManualTag(a) && isManualTag(b)) {
        return 1; // b comes first
      }
    }
    if (!aIsPriority && !bIsPriority) {
      if (isManualTag(a) && !isManualTag(b)) {
        return -1; // a comes first
      }
      if (!isManualTag(a) && isManualTag(b)) {
        return 1; // b comes first
      }
    }
    return 0; // no change in order
  });
  return sortedTags;
};

export const matchingSettingTagInSmartTags = ({
  inputTag,
  smartTags,
}: {
  inputTag: Tag;
  smartTags: CombinedTags[];
}): CombinedTags | null => {
  if (!smartTags || smartTags?.length === 0 || !inputTag) {
    return null;
  }

  return smartTags.find((tag) => getTagId(tag) === getTagId(inputTag)) ?? null;
};

export const filterTagsAgainstSettingsTags = ({ tags, settingsTags }) => {
  return tags.filter(
    (tag) =>
      !!settingsTags.find(
        (settingsTag) => getTagId(settingsTag) === getTagId(tag)
      )
  );
};

export const TAGS_LIMIT = 6;

export function hasReachedTagsLimit(tags) {
  return tags?.length > TAGS_LIMIT;
}

export function isTransparentTag(tag) {
  return tag?.color === "transparent" || parseInt(getTagColorId(tag)) === -1;
}

export function getInternalTagFromUser({ user }) {
  return getUserSmartTags(user)?.find(
    (tag) => tag?.name === SPECIAL_TAGS_TYPE.INTERNAL
  );
}

export function getExternalTagFromUser({ user }) {
  return getUserSmartTags(user)?.find(
    (tag) => tag?.name === SPECIAL_TAGS_TYPE.EXTERNAL
  );
}

export function getSoloTagFromUser({ user }) {
  return getUserSmartTags(user)?.find((tag) => tag?.name === SPECIAL_TAGS_TYPE.SOLO);
}

export const DEFAULT_SPECIAL_COLOR_DATA = {
  color_id: "-1",
  color: "transparent",
};

export function getMatchingTagUserForUserCalendarID({
  userCalendarID,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
  currentUser,
}): {
  userCalendarID: string;
  allCalendars: AllCalendarsState["allCalendars"];
  allLoggedInUsers: User[];
  masterAccount: MasterAccount;
  currentUser: User | null | undefined;
} {
  const matchingCalendar = allCalendars?.[userCalendarID];
  if (!matchingCalendar) {
    return currentUser;
  }
  const getDefaultMatchingUser = () => {
    const calendarUserEmail = getCalendarUserEmail(matchingCalendar);
    return getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail: calendarUserEmail,
    }) ?? currentUser;
  };

  if (!isUserMaestroUser(masterAccount)) {
    return getDefaultMatchingUser();
  }

  const email = getCalendarEmail(matchingCalendar);
  const matchingUser = allLoggedInUsers?.find((user) => isSameEmail(getUserEmail(user), email));
  if (matchingUser && isUserExecutiveUser({user: matchingUser})) {
    return matchingUser;
  }
  return getDefaultMatchingUser();
}

/**
* Determines the appropriate user for tag matching in the context of calendar events.
* For Vimcal EA (Executive Assistant) scenarios, this prioritizes matching with the executive user
* rather than the event owner's user.
* 
* @param {Object} params - The parameters object
* @param {(CalendarEvent | null | undefined)} [params.event] - The calendar event to find a matching user for
* @param {(Record<string, Calendar> | null | undefined)} [params.allCalendars] - Map of all available calendars
* @param {(User[] | null | undefined)} [params.allLoggedInUsers] - Array of all logged-in users
* @param {(MasterAccount | null | undefined)} [params.masterAccount] - The master account for permission checking
* @param {(User | null | undefined)} [params.currentUser] - The current user as fallback
* @returns {(User | null | undefined)} The matching user for the event, or currentUser as fallback
*/
export function getMatchingUIUserForEvent({
  event,
  allCalendars,
  allLoggedInUsers,
  masterAccount,
  currentUser,
}: {
  event: VimcalEvent | null | undefined;
  allCalendars: AllCalendarsState["allCalendars"];
  allLoggedInUsers: User[];
  masterAccount: MasterAccountState["masterAccount"];
  currentUser: User | null | undefined;
}): User | null | undefined {
  const getDefaultMatchingUser = () => {
    return getMatchingUserFromEvent({
      event,
      allCalendars,
      allLoggedInUsers,
    }) || currentUser;
  };

  if (!isUserMaestroUser(masterAccount)) {
    return getDefaultMatchingUser();
  }

  const email = isGoogleEvent(event) ? getEventCalendarProviderID(event) : getEmailBasedOnCalendarId(event, allCalendars);
  const matchingUser = allLoggedInUsers?.find((user) => isSameEmail(getUserEmail(user), email));
  if (matchingUser && isUserExecutiveUser({user: matchingUser})) {
    return matchingUser;
  }
  return getDefaultMatchingUser();
}

interface getMatchingUserAndTagsInput {
  user?: User;
  currentUser: User;
  allLoggedInUsers: User[];
  userEmail?: string;
  shouldForceUseUser?: boolean;
  masterAccount?: MasterAccount;
  where?: string;
}

export function getMatchingUserAndTags({
  user, // explicitly passing in user and not using userEmail to find the user from allLoggedInUsers
  currentUser, // use as backup incase can't find other users
  allLoggedInUsers,
  userEmail,
  shouldForceUseUser, // For settings user select and magic link compatibility
  masterAccount,
  where, // for debugging
}: getMatchingUserAndTagsInput) {
  if (user) {
    return getCurrentPainterColors({ user });
  }

  if (allLoggedInUsers?.length > 0 && userEmail) {
    const matchingUser = getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail,
    });

    if (isUserExecutiveUser({ user: matchingUser })) {
      return getCurrentPainterColors({ user: matchingUser });
    }
  }

  const userPainterColors = getCurrentPainterColors({ user });
  if (isNotEmptyArry(userPainterColors) || shouldForceUseUser) {
    return userPainterColors;
  }

  return getCurrentPainterColors({ user: currentUser });
}

export function isPriorityTag(tag) {
  return tag?.is_prioritized;
}

export const PRIORITY_TAG_STYLE = {
  COLOR: "#D19C03",
  SIZE: 18,
};

export function tagsIncludesPriorityTag(tags) {
  return tags?.some((tag) => isPriorityTag(tag)) ?? false;
}

export function shouldUseTagColorForGoogleEvent({
  event,
  allCalendars,
  currentUser,
  user,
  allLoggedInUsers,
  masterAccount,
}) {
  // use tags if there are no priority tags and the user has not changed the event color.
  if (!isGoogleEvent(event)) {
    return false;
  }
  if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(allCalendars)) {
    return false;
  }
  const allTags = getAllTagsFromEvent({
    event,
    currentUser,
    user,
    allLoggedInUsers,
    masterAccount,
    allCalendars,
  });
  return doesGoogleEventHaveDefaultColor({event, allCalendars}) ||
    tagsIncludesPriorityTag(allTags); // if first tag is a priority tag -> use that color
}

export function getHumanReadableTagName(name) {
  if (!name) {
    return "(No title)";
  }
  if (isTagNameSpecialTag(name)) {
    return convertSpecialTagsToHumanReadable(name);
  }
  return name;
}

// if we did want to add this in later, we can remove duplicate tags by adding removeDuplicateTags in two places:
// adding this right now feels like a bandage solution
// 1. getAllTagsFromEvent
// 2. getCurrentPainterColors
export function removeDuplicateTags(tags) {
  if (isLocal()) {
    return tags;
  }
  return tags?.filter((tag, index, self) =>
    index === self.findIndex((t) => getTagId(t) === getTagId(tag))
  );
}

export function getAllTagsFromEvent({
  event,
  user,
  currentUser,
  allLoggedInUsers,
  masterAccount,
  allCalendars,
}) {
  if (event?.allTags) {
    return event.allTags;
  }

  const tags = getTagsFromEvent(event);
  const smartTags = getEventSmartTags({ event });
  const allTags = generateMergedTagsAndSmartTags({
    tags,
    smartTags,
  });

  const userTagIDs = getMatchingUserAndTags({
    user,
    currentUser,
    allLoggedInUsers,
    userEmail: getUserEmail(getMatchingUIUserForEvent({event, allCalendars, allLoggedInUsers, masterAccount, currentUser})),
    masterAccount,
    where: "getAllTagsFromEvent",
  }).map((tag) => getTagId(tag));
  return allTags.filter((tag) => userTagIDs.includes(getTagId(tag)));
}

export function clearTagsCache() {
  _parsedEtagCache = {};
}

export function getTagUserTokens({masterAccount, tag}) {
  try {
    if (!isUserMaestroUser(masterAccount)) {
      return [];
    }
    return tag?.[TAG_USER_TOKENS_KEY] || [];
  } catch (e) {
    return [];
  }
}

export function getAllUniqueTagsFromNonMagicLinkUsers({
  allLoggedInUsers,
  masterAccount,
  currentUser,
}) {
  const masterAccountUser = getMasterAccountUser({
    allLoggedInUsers,
    masterAccount,
    currentUser: allLoggedInUsers.find((user) => !isUserExecutiveUser({ user })) || currentUser, // backup
  });
  if (!isUserMaestroUser(masterAccount)) {
    return getCurrentPainterColors({ user: masterAccountUser });
  }
  const delegatedNonMagicLinkUsers = allLoggedInUsers.filter((user) => isUserDelegatedUser(user) && !isUserMagicLinkUser({ user }));

  const allTags: Tag[] = [];
  const existingTagIDs: string[] = [];
  getCurrentPainterColors({ user: masterAccountUser }).forEach((tag) => {
    if (!existingTagIDs?.includes(getTagId(tag))) {
      allTags.push(tag);
      existingTagIDs.push(getTagId(tag));
    }
  });
  delegatedNonMagicLinkUsers.forEach((user) => {
    const tags = getCurrentPainterColors({ user });
    tags.forEach((tag) => {
      if (isSmartTag({ painterSetting: tag }) && !existingTagIDs?.includes(getTagId(tag))) {
        allTags.push(tag);
        existingTagIDs.push(getTagId(tag));
      }
    });
  });

  return allTags.filter((tag) => !isTagSpecialTag(tag));
}

export const DEFAULT_NORMAL_TAG_RULE = {
  match_method: PAINTER_MATCH_ANY,
  fields: [],
};
