import { LOCAL_DATA_ACTION } from "../lib/localData";
import {
  convertTrueFalseStringIntoValue,
  handleError,
  localData,
} from "./commonUsefulFunctions";
import { switchOutDelegateBlockedCalendarsWithMatchingExecutiveCalendars } from "./maestroFunctions";
import { AllCalendarsState } from "./stores/SharedAccountData";
import { isEmptyObjectOrFalsey } from "./typeGuards";

type OptionalUserArray = User[] | null | undefined;

export function getBlockedCalendars({
  user,
  allLoggedInUsers,
  allCalendars,
  masterAccount,
}: {
  user: Optional<User>,
  allLoggedInUsers: OptionalUserArray,
  allCalendars: AllCalendarsState["allCalendars"],
  masterAccount: Optional<MasterAccount>,
}): { user: TruncatedUser | null | undefined, calendars: VimcalTruncatedCalendar[] | null | undefined } | null | undefined {
  // returns array of {calendars, user}
  // example of one response below
  // [
  //   {
  //       "user": {
  //           "first_name": "Kenny",
  //           "last_name": "Weveapp",
  //           "full_name": "Kenny Weveapp",
  //           "provider": "outlook",
  //           "email": "kenny@weveapp.com",
  //           "token": "fVMwtTeWcsoV8VPH",
  //           "is_missing_refresh_token": true
  //       },
  //       "calendars": [
  //           {
  //               "user_calendar_id": "ed530608c3370326515c61299e17d74e",
  //               "provider_id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //               "google_id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //               "name": "Kenny",
  //               "name_override": null,
  //               "primary": true,
  //               "hidden": false,
  //               "deleted": false,
  //               "raw_json": {
  //                   "id": "AAMkAGUwYmM2ODkyLWI2M2EtNGQwZS04OGNlLWQyMTZmNGQ2M2Y4YQBGAAAAAACUQ_5SYy9kSJT1osdO1-qtBwDJH9gyifHWQJDvUxlnnXDRAAAAAAEGAADJH9gyifHWQJDvUxlnnXDRAAGB2Xu7AAA=",
  //                   "name": "Kenny",
  //                   "color": "auto",
  //                   "owner": {
  //                       "name": "Kenny",
  //                       "address": "kenny@weveapp.com"
  //                   },
  //                   "canEdit": true,
  //                   "canShare": false,
  //                   "hexColor": "",
  //                   "changeKey": "yR/YMonx1kCQ71MZZ51w0QABjEarbA==",
  //                   "isRemovable": true,
  //                   "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#users('58977136-54f6-401b-9ee1-384d825704eb')/calendars/$entity",
  //                   "isDefaultCalendar": true,
  //                   "canViewPrivateItems": true,
  //                   "isTallyingResponses": true,
  //                   "defaultOnlineMeetingProvider": "unknown",
  //                   "allowedOnlineMeetingProviders": [
  //                       "teamsForBusiness"
  //                   ]
  //               },
  //               "calendar_color": "#a6d1f5",
  //               "user_email": "kenny@weveapp.com"
  //           }
  //       ]
  //   }
  // ]
  try {
    const blockedUserAndCalendars = user?.availability_settings?.blocked_calendars;
    return switchOutDelegateBlockedCalendarsWithMatchingExecutiveCalendars({
      blockedUserAndCalendars,
      masterAccount,
      user,
      allCalendars,
      allLoggedInUsers,
    });
  } catch(error) {
    handleError(error);
    return null;
  }
}

export function setHasBookedOnboarding() {
  localData(LOCAL_DATA_ACTION.SET, "hasBookedOnboarding", true);
}

export function hasBookedOnboarding() {
  const hasBooked = localData(LOCAL_DATA_ACTION.GET, "hasBookedOnboarding");
  return convertTrueFalseStringIntoValue(hasBooked);
}

export function hasAccountBeenOnTeamPlan(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return false;
  }

  return masterAccount.has_been_on_team_plan;
}

export function getMasterAccountStripeEmail(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  return masterAccount?.stripe_email;
}
