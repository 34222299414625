import create from "zustand";
import { persist } from "zustand/middleware";

interface MagicLinkState {
  connectedAccountTokens: { [email: string]: string }
  magicLinkToken: string
  setConnectedAccountTokens: (connectedAccountTokens: { [email: string]: string }) => void
  setMagicLinkToken: (magicLinkToken: string) => void
  resetConnectedAccountTokens: () => void
  resetMagicLinkToken: () => void
}

export const useMagicLink = create(
  // keeps track of every calendar in an index
  persist<MagicLinkState>(
    (set) => ({
      connectedAccountTokens: {},
      magicLinkToken: "",
      setConnectedAccountTokens: (connectedAccountTokens) => {
        set(() => ({ connectedAccountTokens }));
      },
      setMagicLinkToken: (magicLinkToken) => {
        set(() => ({ magicLinkToken }));
      },
      resetConnectedAccountTokens: () => set(() => ({ connectedAccountTokens: {} })),
      resetMagicLinkToken: () => set(() => ({ magicLinkToken: "" })),
    }),
    {
      name: "magic-link-storage", // unique name
    },
  ),
);
