import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  createDBInputObject,
  handleError,
} from "../services/commonUsefulFunctions";
import db from "../services/db";
import {
  getEventMasterEventID,
  getEventStart,
} from "../services/eventResourceAccessors";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";
import { isEmptyArray } from "./arrayFunctions";
import { MAIN_CALENDAR_BROADCAST_VALUES } from "./broadcastValues";
import { getEndTimeUTC, getStartTimeUTC } from "./eventFunctions";

export const DEXIE_EVENT_COLUMNS = {
  USER_EVENT_ID: "user_event_id", // primary key
  CALENDAR_ID: "calendarId",
  DATE: "date",
  SUMMARY: "summary",
  ATTENDEES: "attendees",
  LOCATION: "location",
  EVENT: "event",
};

export async function deleteEventsFromIndexDB({
  userEventIDs,
  userEmail,
  currentUserEmail,
  isOnUserDelete = false,
  where,
}) {
  if (!userEmail && !currentUserEmail) {
    return;
  }
  if (isEmptyArrayOrFalsey(userEventIDs)) {
    return;
  }
  if (isOnUserDelete) {
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LAST_UPDATED_AT_FOR_DELETED_USER_EVENT_IDS, userEventIDs);
  }
  try {
    return await db
      .fetch(userEmail || currentUserEmail)
      .events.bulkDelete(userEventIDs);
  } catch (error) {
    handleError(error);
  }
}

// pulls events from db that has the same masterEventID
export async function getRecurringEventsFromDB({ masterEventID, userEmail }) {
  if (!userEmail || !masterEventID) {
    return;
  }

  try {
    return await db
      .fetch(userEmail)
      .events.filter(
        ({ event }) => getEventMasterEventID(event) === masterEventID
      ) // can't use .where({ masterEventID }) because otherwise user will have to sign out inorder for the db change to take place
      .toArray();
  } catch (error) {
    handleError(error);
  }
}

export async function addEventsIntoIndexDB({ userEmail, events }) {
  if (isEmptyArray(events) || !userEmail) {
    return;
  }

  try {
    let bulkEvents = [];
    events.forEach((e) => {
      if (getEventStart(e)) {
        const eventObject = createDBInputObject(e);
        bulkEvents = bulkEvents.concat(eventObject);
      }
    });
    return db.fetch(userEmail).events.bulkPut(bulkEvents);
  } catch (error) {
    handleError(error);
  }
}

function getFilteredContacts({ newContacts, existingContactsInDB }) {
  if (isEmptyArray(newContacts)) {
    return [];
  }
  if (isEmptyArray(existingContactsInDB)) {
    return newContacts;
  }
  return newContacts.filter((newContact) => {
    const existing = existingContactsInDB.find(
      (existingContact) => existingContact?.email === newContact?.email
    );
    return !existing || !existing.name;
  });
}

export async function addContactsIntoDB({
  userEmail,
  formattedContacts,
  isDomain,
  where,
}) {
  if (isEmptyArray(formattedContacts) || !userEmail) {
    return;
  }

  const emailsToCheck = formattedContacts.map((contact) => contact.email);
  if (isDomain) {
    const existingDomains = await db
      .fetch(userEmail)
      .domainUsers.where("email")
      .anyOf(emailsToCheck)
      .toArray();

    // do not add in contacts where name is null if we already have a name for that email
    const domainsToPut = getFilteredContacts({
      newContacts: formattedContacts,
      existingContactsInDB: existingDomains,
    });
    if (isEmptyArray(domainsToPut)) {
      return;
    }
    db.fetch(userEmail)
      .domainUsers.bulkPut(domainsToPut)
      .then(() => {})
      .catch((err) => {
        handleError(err);
        // Throwing an error when key already exists is correct
        // https://dexie.org/docs/Table/Table.add()
        // Do not need to report
      });
  } else {
    const existingContacts = await db
      .fetch(userEmail)
      .domainUsers.where("email")
      .anyOf(emailsToCheck)
      .toArray();
    const contactsToPut = getFilteredContacts({
      newContacts: formattedContacts,
      existingContactsInDB: existingContacts,
    });
    if (isEmptyArray(contactsToPut)) {
      return;
    }
    db.fetch(userEmail)
      .contacts.bulkPut(contactsToPut)
      .then(() => {})
      .catch((err) => {
        handleError(err);
        // Throwing an error when key already exists is correct
        // https://dexie.org/docs/Table/Table.add()
        // Do not need to report
      });
  }
}

export function addBuildingsIntoDB({ userEmail, formattedBuildings }) {
  if (!userEmail || !formattedBuildings || formattedBuildings.length === 0) {
    return;
  }

  db.fetch(userEmail)
    .buildings.bulkPut(formattedBuildings)
    .then(() => {})
    .catch((err) => {
      handleError(err);
    });
}

export function addConferenceRoomsIntoDB({
  userEmail,
  formattedConferenceRooms,
}) {
  if (!userEmail || isEmptyArray(formattedConferenceRooms)) {
    return;
  }

  db.fetch(userEmail)
    .conferenceRooms.bulkPut(formattedConferenceRooms)
    .then(() => {})
    .catch((err) => {
      handleError(err);
    });
}

export function isDBEventItemWithinWindow({ item, windowStart, windowEnd }) {
  return (
    getStartTimeUTC(item?.event) <= windowEnd?.toISOString() &&
    getEndTimeUTC(item?.event) >= windowStart?.toISOString()
  );
}

export function isEventWithinJSWindow({ event, windowStart, windowEnd }) {
  if (!windowStart?.toISOString || !windowEnd?.toISOString) {
    // need to pass in valid js dates for window start and end
    return false;
  }
  return (
    getStartTimeUTC(event) <= windowEnd.toISOString() &&
    getEndTimeUTC(event) >= windowStart.toISOString()
  );
}

// only if event is within the window but not touching
export function isEventWithinJSWindowNotIncludingTouching({
  event,
  windowStart,
  windowEnd,
}) {
  if (!windowStart?.toISOString || !windowEnd?.toISOString) {
    // need to pass in valid js dates for window start and end
    return false;
  }
  return (
    getStartTimeUTC(event) < windowEnd.toISOString() &&
    getEndTimeUTC(event) > windowStart.toISOString()
  );
}

export async function getEventsFromDB({
  userEmail,
  windowStart,
  windowEnd,
  userCalendarIDs,
}) {
  try {
    return db
      .fetch(userEmail)
      .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
      .startsWithAnyOfIgnoreCase(userCalendarIDs)
      .and((item) => {
        return isDBEventItemWithinWindow({
          item,
          windowStart,
          windowEnd,
        });
      })
      .toArray();
  } catch (error) {
    handleError(error);
  }
}
