import _ from "underscore";

type EventStyle = {
  className: string,
  style: React.CSSProperties
}

/**
 * caches style for events so we have once source of truth everywhere
 */
let EVENT_STYLE_CACHE: Partial<Record<string, EventStyle>> = {};


export function getEventStyleCache(uniqueEtag: string) {
  return EVENT_STYLE_CACHE[uniqueEtag];
}

export function clearEventStyleCache() {
  EVENT_STYLE_CACHE = {};
}

export function removePropertiesFromEventStyleCache(keysArray: string[]) {
  EVENT_STYLE_CACHE = _.omit(EVENT_STYLE_CACHE, keysArray);
}

export function addEventStyleToCache({ uniqueEtag, eventClassnameAndStyle }: {
  uniqueEtag: string
  eventClassnameAndStyle: EventStyle
}) {
  EVENT_STYLE_CACHE[uniqueEtag] = eventClassnameAndStyle;
}

export function getEventBackgroundColorFromEventCache(uniqueEtag: string) {
  try {
    const eventStyleCache = getEventStyleCache(uniqueEtag);
    return eventStyleCache?.style?.backgroundColor;
  } catch (e) {
    return null;
  }
}

export function getEntireStyleCache() {
  return EVENT_STYLE_CACHE;
}
