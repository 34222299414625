import { handleError } from "../services/commonUsefulFunctions";
import { devPrint } from "../services/devFunctions";
import { EXCLUDED_DOMAINS } from "../services/globalVariables";

type OptionalString = string | null | undefined;

export const WHATSAPP_BASE_URL = "https://wa.me/";

export function getCountOfSubstringInString({
  str,
  substr,
}: {
  str: OptionalString;
  substr: OptionalString;
}) {
  if (!str || !substr) {
    return 0;
  }

  return str.split(substr).length - 1;
}

export function doesStringContainTimeAMPM(str: OptionalString) {
  if (!str) {
    return false;
  }
  // \d{1,2}: Matches one or two digits (for the hour part).
  // (:\d{2})?: Matches a colon followed by two digits (for the minute part), but this part is optional.
  // (?:am|pm): Matches either "am" or "pm", case-insensitive.
  // \b: Word boundaries to ensure that the time pattern is a whole word.
  const timeRegex = /\b\d{1,2}(:\d{2})?(?:am|pm)\b/i;
  return timeRegex.test(str);
}

export function doesStringContain24HourTime(str: OptionalString) {
  if (!str) {
    return false;
  }

  const timeRegex = /\b([01]?[0-9]|2[0-3]):[0-5][0-9]\b/i;
  return timeRegex.test(str);
}

export function doesStringContainTime(str: OptionalString) {
  return doesStringContainTimeAMPM(str) || doesStringContain24HourTime(str);
}

export function extractEmailsFromString(text: string) {
  // Example usage -> parses emails from , and <>
  // const input = 'john.doe@example.com, "Jane Doe" <jane.doe@example.com>, foo@bar.com';
  // or John Li <john@weveapp.com>, Alex Bayer <alex@weveapp.com>, Michael Zhao <mike@weveapp.com>, Bruno Vieira <bruno@weveapp.com>, Dillon Hanley <dillon@weveapp.com>, Bryan Marshall <bryan@weveapp.com>, Aamkaguwymm2odkylwi2m2etngqwzs04ognllwqymtzmngq2m2y4yqbgaaaaaacuq_5syy9ksjt1osdo1-qtbwdjh9gyifhwqjdvuxlnnxdraaaaaaegaadjh9gyifhwqjdvuxlnnxdraaaaaq98aaa=, Dylan McLeod <dylan@vimcal.com>, Seamus Le <seamus@weveapp.com>, max@vimcal.com <max@vimcal.com>
  // const emails = extractEmails(input);
  try {
    const emailPattern = /<([^>]+)>|[\w.-]+@[\w.-]+\.\w+/g;
    const matches = text.match(emailPattern);

    if (!matches) {
      return []; // No matches found, return an empty array
    }

    return matches.map((email) => email.replace(/[<>]/g, "")); // Remove angle brackets if any
  } catch (err) {
    devPrint("Error in extractEmailsFromString: ", err);
    return [];
  }
}

export function getAorAn(word: OptionalString) {
  if (!word) {
    return "";
  }
  const firstLetter = word[0];
  const vowels = ["a", "e", "i", "o", "u"];
  return vowels.includes(firstLetter) ? "an" : "a";
}

export function isRichText(inputString: string) {
  // Regular expression to detect HTML-like tags
  const tagPattern = /<[^>]+>/;
  return tagPattern.test(inputString);
}

export function isRichTextAndEmpty(input: OptionalString) {
  if (!input) {
    return false;
  }
  if (!isRichText(input)) {
    return false;
  }
  // Create a temporary DOM element to parse the string
  let tempElement: HTMLDivElement | null = document.createElement("div");
  tempElement.innerHTML = input;

  // Extract the inner text from the parsed HTML
  const innerText = tempElement.textContent || tempElement.innerText || "";
  tempElement = null; // remove reference to dom

  // Check if the inner text is empty (after trimming whitespace)
  return innerText.trim().length === 0;
}

// given Dylan McLeod <dylan@vimcal.com>, michael@israel-impact.com
// Vimcal <vimcal@cfounlimited.com>
// Alex Bayer <alex@vimcal.com>, Dillon Hanley <dillon@vimcal.com>, Dylan McLeod <dylan@vimcal.com>, Johnny Wu <jwu@vimcal.com>, nick.lafferty@gmail.com
// if only email -> alksjdflkajd@ylahoo.com
// alksjdflkajd@ylahoo.com, mike@weveapp.com
// alksjdflkajd@ylahoo.com, Michael Zhao <mchlzhao@gmail.com>, mike@weveapp.com
// only name -> Dylan McLeod
export function getNameAndEmailFromCopiedEmailString(
  inputString: OptionalString,
): { name?: string; email: string } | null {
  if (!inputString) {
    return null;
  }
  // Split the string by comma and take the first element
  const firstContact = inputString.split(",")[0].trim();
  if (!firstContact) {
    return null;
  }

  // Check if the contact includes a name and an email in angle brackets
  const match = firstContact.match(/(?:([^<]+)\s)?<([^>]+)>|(\S+@\S+)/);

  // If it's a name and email pair
  if (match && match[2]) {
    return {
      name: match[1]?.trim(),
      email: lowerCaseAndTrimStringWithGuard(match[2]),
    };
  }
  // If it's just an email
  else if (match && match[3]) {
    return { email: lowerCaseAndTrimStringWithGuard(match[3]) };
  }

  return null;
}

export function protectedTrim(inputString: OptionalString) {
  if (!inputString) {
    return "";
  }
  return inputString.trim();
}

export function isEmailGroupEmail(email: OptionalString) {
  return email?.includes("group.") ?? false;
}

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputEvent =
  | React.ChangeEvent<InputElement>
  | React.KeyboardEvent<InputElement>;

/**
 * Note: When using this function for keydown events, the value returned does not
 * account for the key that the user just pressed.
 *
 * TODO: Add unit tests.
 */
export function getInputStringFromEvent(e: InputEvent) {
  if (!e?.target) {
    return "";
  }
  // The target type is not inferred properly for keyboard events.
  const target = e.target as InputElement;
  return target.value ?? "";
}

export function lowerCaseAndTrimString(str: undefined): undefined
export function lowerCaseAndTrimString(str: null): null
export function lowerCaseAndTrimString(str: string): string
export function lowerCaseAndTrimString(str: string | null): string | null
export function lowerCaseAndTrimString(str: OptionalString): OptionalString {
  try {
    if (!str) {
      return str;
    }
    return str.toLowerCase().trim();
  } catch (error) {
    return str;
  }
}

export function lowerCaseAndTrimStringWithGuard(inputString: OptionalString) {
  try {
    if (!inputString) {
      return "";
    }
    return lowerCaseAndTrimString(inputString) || "";
  } catch (error) {
    return "";
  }
}

export function equalAfterTrimAndLowerCased(
  string1: OptionalString,
  string2: OptionalString,
) {
  return string1?.trim().toLowerCase() === string2?.trim().toLowerCase();
}

export function isSameEmail(email1: OptionalString, email2: OptionalString) {
  try {
    if (!email1 || !email2) {
      return false;
    }
    return equalAfterTrimAndLowerCased(email1, email2);
  } catch (error) {
    return false;
  }
}

export function capitalizeFirstLetter(string: OptionalString) {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetterOfEveryWord(word: OptionalString) {
  if (!word) {
    return "";
  }

  return word.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

/**
 * Removes all non-alphabetical characters (including digits) and replaces spaces
 * with underscores.
 */
export function takeOutSpecialCharacters(obj: string) {
  return obj
    .replace(/[^a-zA-Z ]/g, "")
    .split(" ")
    .join("_");
}

/**
 * Removes all non-alphabetical characters (including digits). Spaces are left in.
 */
export function removeAllSpecialCharacters(obj: string) {
  return obj.replace(/[^a-zA-Z ]/g, "");
}

export function pluralize(number: number, string: string) {
  if (number !== 1) {
    return string + "s";
  } else {
    return string;
  }
}

export function isUrl(str: OptionalString) {
  if (!str) {
    return false;
  }

  const lowerCaseString = str.toLowerCase();

  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  return regexp.test(lowerCaseString);
}

export function isValidEmail(email: OptionalString) {
  try {
    if (!email) {
      return false;
    }

    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression/201378#201378
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // https://emailregex.com
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = email.match(emailRegex);
    return !!isValidEmail;
  } catch (error) {
    return false;
  }
}

export function stringHasNumber(myString: string) {
  return /\d/.test(myString);
}

export function removePlusAndCapitalize(string: OptionalString) {
  return string?.replace("+", "").toUpperCase();
}

export function replacePlusWithAndAndCapitalize(string: OptionalString) {
  return string?.toUpperCase().replace("+", " and ");
}

/**
 * Truncate a string to be of length n-1.
 *
 * TODO: Would it be clearer for n to be the length?
 */
export function truncateString(
  string: OptionalString,
  n: number,
  showEllipsis = true,
) {
  if (!string) {
    return "";
  }

  const ellipsis = showEllipsis ? "..." : "";
  return string.length > n ? string.substring(0, n - 1) + ellipsis : string;
}

export function getEmailDomain(email: OptionalString) {
  if (!email) {
    return "";
  }

  if (!email.includes("@")) {
    return "";
  }

  // pop should get us the domain since last at should be right in front
  return lowerCaseAndTrimStringWithGuard(email.split("@").pop());
}

/**
 * TODO: Combine with extractEmailsFromString?
 */
export function extractEmails(text: OptionalString) {
  if (!text) {
    return [];
  }
  // https://stackoverflow.com/questions/42407785/regex-extract-email-from-strings
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
}

export function replaceUnixSpaceWithStringSpace(str: OptionalString) {
  // replace empty string and trim
  if (!str) {
    return "";
  }

  return str.replace(new RegExp(String.fromCharCode(160), "gmi"), " ").trim();
}

export function slugifyString(slugInput: string) {
  return slugInput
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export function formatEmail(email: string | null | undefined) {
  try {
    if (!email) {
      return "";
    }
    return lowerCaseAndTrimStringWithGuard(email);
  } catch (error) {
    return email || "";
  }
}

export function isGenericDomain(domain: string) {
  return (EXCLUDED_DOMAINS as Readonly<string[]>).includes(domain);
}

export function cleanUpDomainEmail(email: string | null | undefined) {
  if (!email) {
    return "";
  }
  return email.replaceAll("$", "");
}

export function extractWhatsappURL(text: string | null | undefined) {
  if (!text) {
    return null;
  }
  const regex = /https:\/\/wa\.me\/\+\d+/gi;
  const match = text.match(regex);

  return match?.[0]; // Output: https://wa.me/+14158174567
}

/**
 * Converts a string like "Mon" or "mon" to "Monday"
 * @param shortDay - The abbreviated day string to convert.
 * @returns The full name of the day or "Invalid day abbreviation" if not found.
 * @searchable Converts abbreviated day to full day name
 */
export function getFullDayName(shortDay: string | null | undefined) {
  if (!shortDay) {
    return "";
  }
  const daysIndex: Record<string, string> = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  };

  const formattedShortDay = lowerCaseAndTrimStringWithGuard(shortDay); // Convert to lowercase to handle cases like "Sun" or "MON"
  return daysIndex[formattedShortDay.slice(0, 3)] || "";
}

// turn html space into normal a text space
export function replaceEmptySpaceWithTextEmptyString(
  str: string | null | undefined,
) {
  if (!str) {
    return "";
  }

  try {
    return str.replaceAll("&nbsp;", " ").replaceAll("\u00A0", " ");
  } catch (error) {
    return str;
  }
}

export function removeLeadingSpacesInTags(str: string | null | undefined) {
  if (!str) {
    return "";
  }
  try {
    return str.replace(/(<a[^>]*>)\s+(\w)/g, "$1$2") || "";
  } catch (error) {
    return str;
  }
}

// because outlook and google have different formats:
// only compare up to 2024-10-30T18:00:40.111
// 2024-10-30T18:00:40.119Z
// 2024-11-02T18:30:21.7899815Z
export function isGreatThanUpdatedAt(
  updatedAt1: OptionalString,
  updatedAt2: OptionalString,
) {
  try {
    if (!updatedAt1 || !updatedAt2) {
      return false;
    }
    if (updatedAt1 === updatedAt2) {
      return false;
    }
    const precisionLength = 22;
    const trimmedDate1 = updatedAt1.slice(0, precisionLength);
    const trimmedDate2 = updatedAt2.slice(0, precisionLength);
    return trimmedDate1 > trimmedDate2;
  } catch (error) {
    return false;
  }
}

export function isGreatThanOrEqualToUpdatedAt(
  updatedAt1: OptionalString,
  updatedAt2: OptionalString,
) {
  try {
    if (!updatedAt1 || !updatedAt2) {
      return false;
    }
    const precisionLength = 22;
    const trimmedDate1 = updatedAt1.slice(0, precisionLength);
    const trimmedDate2 = updatedAt2.slice(0, precisionLength);
    return trimmedDate1 >= trimmedDate2;
  } catch (error) {
    return false;
  }
}

export function getDomainFromEmail(email: string | null | undefined, ignoreGenericDomains = false) {
  try {
    if (!email?.includes("@")) {
      return null;
    }

    // do not check for gmail, outlook, yahoo
    const domain = lowerCaseAndTrimStringWithGuard(email?.split("@")[1]);
    if (!domain || (!ignoreGenericDomains && (EXCLUDED_DOMAINS as Readonly<string[]>).includes(domain))) {
      return null;
    }

    return `@${domain}`;
  } catch (error) {
    return null;
  }
}

export function boldPlainText(text: string) {
  return `<strong>${text}</strong>`;
}

export function getAbsoluteDifferenceInLengthOfStrings(
  str1?: string,
  str2?: string,
) {
  const length1 = str1?.length || 0;
  const length2 = str2?.length || 0;

  // Return the absolute difference in lengths
  return Math.abs(length1 - length2);
}

export async function copyFormattedText({
  htmlContent,
  text,
}: {
  htmlContent: string;
  text: string;
}) {
  try {
    // Create a blob with HTML content type
    const blob = new Blob([htmlContent], { type: "text/html" });

    // Create clipboard data
    const data = new ClipboardItem({
      "text/html": blob,
      "text/plain": new Blob([text], { type: "text/plain" }),
    });

    // Write to clipboard
    await navigator.clipboard.write([data]); // note: do not use copyContent here since we're passing in array of data
  } catch (err) {
    handleError(err);
  }
}

export function getHtmlAndTextContent(str: string) {
  const text = removeAllTags(str);
  const htmlContent = str.replaceAll("\n", "<br />");
  return {
    text,
    htmlContent,
  };
}

export function removeStrongTags(str: string) {
  return str?.replaceAll("<strong>", "").replaceAll("</strong>", "");
}

export function removeAllTags(str: string) {
  return str?.replaceAll(/<[^>]*>?/gm, "");
}

export function doesStringContainBoldTags(str: string) {
  return str?.includes("<strong>");
}

export function extractBoldText(text: string) {
  const matches = text?.match(/<strong>(.*?)<\/strong>\s*(.*)/);

  if (!matches) {
    return { boldText: "", afterBold: "" };
  }

  return {
    boldText: matches[1], // The text between <strong> tags
    afterBold: matches[2], // Everything after </strong>
  };
}

export function strContainsDateAndTime(input?: string) {
  if (!input) {
    return false;
  }
  // Regex to match a date (e.g., Dec 6, December 6, Dec 6 (Fri))
  const dateRegex = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{1,2}(?:\s\(\w+\))?\b/i;

  // Regex to match a time (e.g., 10:30am, 1pm, 14:00)
  const timeRegex = /\b\d{1,2}:\d{2}(?:am|pm)?|\b\d{1,2}(?:am|pm)\b/i;

  // Check if the string contains both date and time
  return dateRegex.test(input) && timeRegex.test(input);
}

export function getDateAndTimeFromInput(input?: string) {
  if (!input) {
    return null;
  }
  const parts = input.split("):");
  const date = parts[0] + "):";
  const time = parts[1];
  return {
    date,
    time,
  };
}

export function isStringInString(str1: string, str2: string) {
  try {
    return lowerCaseAndTrimStringWithGuard(str1).includes(lowerCaseAndTrimStringWithGuard(str2));
  } catch (error) {
    return false;
  }
}

export function splitStringIntoEmails(str: string) {
  try {
    if (!str) {
      return [];
    }

    // Common email delimiters and formatting characters
    const cleanedStr = lowerCaseAndTrimStringWithGuard(str)
      .replaceAll(/[\t\n\r,;]/g, " ")     // Handle tabs, newlines, commas, semicolons
      .replaceAll(/[<>[\](){}]/g, " ")    // Handle various brackets
      .replaceAll(/\s+/g, " ")            // Collapse multiple spaces into one
      .replaceAll(/["']/g, "")            // Remove quotes
      .replaceAll(/mailto:/g, "")         // Remove mailto: prefixes
      .trim();

    return cleanedStr
      .split(" ")
      .map(email => formatEmail(email))
      .filter(email => isValidEmail(email));
  } catch (error) {
    // Consider logging the error here
    return [];
  }
}

export function convertPToDivs(str: string | null | undefined) {
  try {
    if (!str) {
      return "";
    }
    return str
      .replaceAll("<p>", "<div>")
      .replaceAll("</p>", "</div>")
      .replaceAll("<p/>", "<div/>")
      .replaceAll("<p />", "<div />");
  } catch (error) {
    return str;
  }
}

// for cases where backend could return error as object
export function safeCheckStringIncludes(str: string | null | undefined, search: string | null | undefined) {
  try {
    if (!str || !search) {
      return false;
    }
    return str.includes(search);
  } catch (error) {
    return false;
  }
}
