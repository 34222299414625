import { nextFriday, nextMonday, parseISO } from "date-fns";
import {
  createAbbreviationForTimeZone,
  guessTimeZone,
  isAfterDay,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  isValidTimeZone,
} from "../services/commonUsefulFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { getCalendarIsPrimary, getCalendarObject } from "../services/calendarAccessors";
import { getMatchingCalendarsForUser } from "./calendarFunctions";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getInternalDomainAndEmails, getTrips } from "./settingsFunctions";
import { getMatchingExecutiveCalendar, shouldHideDelegatedUser, switchOutDelegateBlockedCalendarsWithMatchingExecutiveCalendars } from "../services/maestroFunctions";

export function getTripName(trip) {
  return trip?.name ?? "";
}

export function getTripTimeZone(trip) {
  if (isValidTimeZone(trip?.time_zone)) {
    return trip.time_zone;
  }
  return guessTimeZone();
}

export function getTripTimeZoneAbbreviation(trip) {
  return createAbbreviationForTimeZone(getTripTimeZone(trip));
}

export function getTripStartDate(trip) {
  return getTripRawStartDate(trip)
    ? parseISO(getTripRawStartDate(trip))
    : nextMonday(new Date());
}

function getTripRawStartDate(trip) {
  return trip?.start_date;
}

function getTripRawEndDate(trip) {
  return trip?.end_date;
}

export function getTripEndDate(trip) {
  return getTripRawEndDate(trip)
    ? parseISO(getTripRawEndDate(trip))
    : nextFriday(nextMonday(new Date()));
}

export function getUpcomingTrips({ masterAccount, user }) {
  const trips = getTrips({ masterAccount, user });

  return filterAndSortUpcomingTrips(trips);
}

export function filterAndSortUpcomingTrips(trips) {
  if (isEmptyArray(trips)) {
    return [];
  }
  return trips
    .filter((trip) => isTripInTheFuture(trip))
    .sort((a, b) =>
      getTripRawStartDate(a).localeCompare(getTripRawStartDate(b)),
    );
}

function isTripInTheFuture(trip) {
  return isAfterDay(getTripEndDate(trip), new Date());
}

export function isTripOverlappingOtherTrips({ trip, allTrips }) {
  return allTrips.find((otherTrip) => {
    return (
      isSameOrBeforeDay(getTripStartDate(trip), getTripEndDate(otherTrip)) &&
      isSameOrAfterDay(getTripEndDate(trip), getTripStartDate(otherTrip))
    );
  });
}

export function isCurrentlyOnTrip(allTrips) {
  return !!getCurrentTrip(allTrips, new Date());
}

export function getCurrentTrip(allTrips, inputDate) {
  const comparisonDate = inputDate ?? new Date();
  return allTrips?.find((trip) => {
    return (
      isSameOrAfterDay(comparisonDate, getTripStartDate(trip)) &&
      isSameOrBeforeDay(comparisonDate, getTripEndDate(trip))
    );
  });
}

export function getTripID(trip) {
  if (isEmptyObjectOrFalsey(trip)) {
    return "";
  }
  return `${getTripName(trip)}-${getTripRawStartDate(trip)}-${getTripRawEndDate(
    trip,
  )}-${getTripTimeZone(trip)}`;
}

// combine the id of all trips into a single string to compare
export function getAllTripsID(trips) {
  if (isEmptyArray(trips)) {
    return "";
  }
  return trips.map((trip) => getTripID(trip)).join("-");
}

export function getNewPersonalLinkSelectedUserAndCalendar({
  allCalendars,
  user,
  masterAccount,
  allLoggedInUsers,
}) {
  if (shouldHideDelegatedUser({user, allCalendars})) {
    const matchingExecutiveCalendar = getMatchingExecutiveCalendar({
      allCalendars,
      user,
    });
    return [{
      user,
      calendars: matchingExecutiveCalendar ? [getCalendarObject(matchingExecutiveCalendar)] : [],
    }];
  }
  const matchingCalendars = getMatchingCalendarsForUser({allCalendars, user});

  if (!matchingCalendars) {
    return [{
      user,
      calendars: [],
    }];
  }

  const filteredCalendars = Object.values(matchingCalendars).filter(
    (calendar) => getCalendarIsPrimary(calendar),
  );
  const selectedCalendars = filteredCalendars.map(calendar => getCalendarObject(calendar));
  return switchOutDelegateBlockedCalendarsWithMatchingExecutiveCalendars({
    blockedUserAndCalendars: [{
      user,
      calendars: selectedCalendars,
    }],
    masterAccount,
    user,
    allCalendars,
    allLoggedInUsers,
  });
}

export function getPersonalLinkToken(personalLink) {
  return personalLink?.token ?? "";
}

export function getPersonalLinkInternalLabel(personalLink, useBackup=true) {
  if (!useBackup) {
    return personalLink?.nickname || "";
  }
  return personalLink?.nickname || personalLink?.name || "(No title)";
}

export const BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY = "ignore_internal_conflicts";
export function getPersonalLinkIsIgnoreInternalConflicts({personalLink, masterAccount, user}) {
  if (isEmptyArray(getInternalDomainAndEmails({masterAccount, user}))) {
    return false;
  }
  return personalLink?.[BACKEND_IGNORE_INTERNAL_CONFLICTS_KEY] ?? false;
}
