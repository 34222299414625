import { isGoogleWorkspaceCalendar, isCalendarOutlookCalendar } from "../lib/calendarFunctions";
import { safeJSONParse } from "../lib/jsonFunctions";
import { LOCAL_DATA_ACTION } from "../lib/localData";
import { OUTLOOK_SHOW_AS } from "../resources/outlookVariables";
import { getCalendarIsPrimary } from "./calendarAccessors";
import { convertAllObjectDatesToISO, localData, parseAllObjectDatesToISO, ReplaceSpaceWithUnderscore } from "./commonUsefulFunctions";
import { BUSY_DURING_EVENT, FREE_DURING_EVENT, OUT_OF_OFFICE_DURING_EVENT } from "./googleCalendarService";
import { getTemplateTitle } from "./templateFunctions";
import { isUndefined } from "./typeGuards";

export const EVENT_HAS_MODIFY_PERMISSION_AND_IS_NOT_AN_ORGANIZER = "eventHasModifyPermissionAndIsNotAnOrganizer";

export function createTemplateSummaryIndex(templates) {
  if (!templates || templates.length === 0) {
    return [];
  }

  let summaryList = {};

  templates.forEach(t => {
    const templateTitle = getTemplateTitle(t);
    if (templateTitle) {
      summaryList[templateTitle.toLowerCase().trim()] = t;
    }
  });

  return summaryList;
}

export function getMatchingTemplates(param) {
  let {
    templatesSummaryIndex,
    query,
    knownTemplate,
  } = param;

  let lowerCaseQuery = query.toLowerCase().replace(new RegExp(String.fromCharCode(160), "gmi"), " ");
  let existingTemplatesNames = Object.keys(templatesSummaryIndex);
  let templateMatches = [];

  if (lowerCaseQuery.length > 0) {
    existingTemplatesNames.forEach(t => {
      if (t.includes(lowerCaseQuery)) {
        templateMatches = templateMatches.concat(t);
      }
    });
  }

  let suggestions = [];
  if (templateMatches.length > 0) {
    if (templateMatches.length === 1
      && knownTemplate
      && getTemplateTitle(templatesSummaryIndex[templateMatches[0]])?.trim().toLowerCase() === knownTemplate
    ) {
      // only match is template that has already been applied
      // do nothing here
    } else {
      templateMatches.forEach((t, index) => {
        const template = templatesSummaryIndex[t];
        const templateTitle = getTemplateTitle(template);
        suggestions = suggestions.concat({
          description: templateTitle,
          index: `template_suggestion_${index}`,
          active: index === 0,
          key: ReplaceSpaceWithUnderscore(templateTitle),
          template,
        });
      });
    }
  }

  return suggestions;
}

export function makeAllSuggestionsInactive(suggestions) {
  if (!suggestions || suggestions.length === 0) {
    return [];
  }

  let updatedSuggestions = [];
  let element;

  suggestions.forEach(s => {
    element = s;
    element.active = false;
    element.isActive = false;

    updatedSuggestions = updatedSuggestions.concat(element);
  });

  return updatedSuggestions;
}

export function pressUpSuggestion(currentIndex, suggestions, scrollToOption) {
  let updatedSuggestions = makeAllSuggestionsInactive(suggestions);

  if (currentIndex === 0) {
    // first suggestion
    return {suggestion: updatedSuggestions, index: null};
  } else if (currentIndex === undefined || currentIndex === null) {
    // Cant do !currentIndex because 0 would also fail here
    // Nothing is currently active/highlighted
    let lastSuggestionIndex = suggestions.length - 1;

    if (lastSuggestionIndex >= 0 && updatedSuggestions[lastSuggestionIndex]) {
      // Make sure the first index exists
      updatedSuggestions[lastSuggestionIndex].active = true;
      updatedSuggestions[lastSuggestionIndex].isActive = true;

      scrollToOption && scrollToOption(updatedSuggestions[lastSuggestionIndex]);
    }

    return {suggestion: updatedSuggestions, index: 0};
  } else {
    updatedSuggestions[currentIndex - 1].active = true;
    updatedSuggestions[currentIndex - 1].isActive = true;

    scrollToOption && scrollToOption(updatedSuggestions[currentIndex - 1]);

    return {suggestion: updatedSuggestions, index: currentIndex - 1};
  }
}

export function getCurrentActiveIndex(list) {
  if (!list || list.length === 0) {
    return null;
  }

  let index = null;

  list.forEach((e, i) => {
    if (!index && (e.active || e.isActive)) {
      index = i;
    }
  });

  return index;
}

export function pressDownSuggestions(currentIndex, suggestions, scrollToOption) {
  let updatedSuggestions = makeAllSuggestionsInactive(suggestions);

  if (currentIndex === suggestions.length - 1) {
    scrollToOption && scrollToOption(updatedSuggestions[0]);

    // last suggestion
    return {suggestion: updatedSuggestions, index: null};
  } else if (currentIndex === undefined || currentIndex === null) {
    // Nothing is currently active/highlighted
    if (updatedSuggestions[0]) {
      scrollToOption && scrollToOption(updatedSuggestions[0]);
      // Make sure the first index exists
      updatedSuggestions[0].active = true;
      updatedSuggestions[0].isActive = true;
    }

    return {suggestion: updatedSuggestions, index: 0};
  } else {
    // go down one
    updatedSuggestions[currentIndex + 1].active = true;
    updatedSuggestions[currentIndex + 1].isActive = true;

    let activeSuggestion = updatedSuggestions[currentIndex + 1];

    scrollToOption && scrollToOption(activeSuggestion);

    return {suggestion: updatedSuggestions, index: currentIndex + 1};
  }
}

const OUTLOOK_EVENT_FREE_BUSY_OPTIONS = [
  { label: "Free", value: FREE_DURING_EVENT },
  { label: "Busy", value: BUSY_DURING_EVENT },
  { label: "OOO", menuLabel: "Out of office", value: OUT_OF_OFFICE_DURING_EVENT },
  { label: "Tentative", menuLabel: "Tentative", value: OUTLOOK_SHOW_AS.TENTATIVE }, // TODO: move to outlook option?
];
const GOOGLE_EVENT_FREE_BUSY_OPTIONS = [
  { label: "Free", value: FREE_DURING_EVENT },
  { label: "Busy", value: BUSY_DURING_EVENT },
  { label: "OOO", menuLabel: "Out of office", value: OUT_OF_OFFICE_DURING_EVENT },
];

export function getTransparencyOptions({ calendar, isTemplate, originalTransparency }) {
  const isPrimaryCalendar = getCalendarIsPrimary(calendar);

  if (isCalendarOutlookCalendar(calendar)) {
    return OUTLOOK_EVENT_FREE_BUSY_OPTIONS;
  }

  // Google OOO events are limited to Google Workspace users.
  if (!isGoogleWorkspaceCalendar(calendar)) {
    return GOOGLE_EVENT_FREE_BUSY_OPTIONS.filter(option => option.value !== OUT_OF_OFFICE_DURING_EVENT);
  }

  if (isUndefined(originalTransparency) && isPrimaryCalendar) {
    return GOOGLE_EVENT_FREE_BUSY_OPTIONS;
  }

  if (isTemplate && isPrimaryCalendar) {
    return GOOGLE_EVENT_FREE_BUSY_OPTIONS;
  }

  if (originalTransparency === OUT_OF_OFFICE_DURING_EVENT) {
    return GOOGLE_EVENT_FREE_BUSY_OPTIONS.filter(option => option.value === OUT_OF_OFFICE_DURING_EVENT);
  }

  return GOOGLE_EVENT_FREE_BUSY_OPTIONS.filter(option => option.value !== OUT_OF_OFFICE_DURING_EVENT);
}

export const LOCAL_DATA_EVENT_FORM_STATE_NAME = "STORED_EVENT_FORM_STATE";

export function storeLocalEventFormState({ description, state }) {
  /* Shallow clone and only change description */
  const clonedState = { ...state, description };
  const stateWithDateConversion = convertAllObjectDatesToISO(clonedState);

  localData(
    LOCAL_DATA_ACTION.SET,
    LOCAL_DATA_EVENT_FORM_STATE_NAME,
    JSON.stringify(stateWithDateConversion),
  );

  return;
}

export function removeLocalEventFormState() {
  localData(
    LOCAL_DATA_ACTION.DELETE,
    LOCAL_DATA_EVENT_FORM_STATE_NAME,
  );

  return;
}

export function getLocalEventFormState() {
  const storedStateJSON = localData(
    LOCAL_DATA_ACTION.GET,
    LOCAL_DATA_EVENT_FORM_STATE_NAME,
  );
  const parsedState = parseAllObjectDatesToISO(safeJSONParse(storedStateJSON));

  return parsedState;
}
