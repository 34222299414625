import googleColors from "../services/googleColors";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { isLastIndexOfArray } from "./arrayFunctions";
import { capitalizeFirstLetter } from "./stringFunctions";

export const PAINTER_MATCH_ALL = "all";
export const PAINTER_MATCH_ANY = "any";
export const PAINTER_RULE_CONDITION_EQUAL = "equal";
export const PAINTER_RULE_CONDITION_MATCH = "match";
export const SMART_TAG_VALUE_RULE = {
  CONTAINS_ANY: "contains_any",
  CONTAINS_ALL: "contains_all",
  EQUAL: "equal",
  IN_DOMAIN: "in_domain", // same as domain
  MATCH: "match", // legacy
};

export function getColorFromID({ id, color }) {
  return color ?? googleColors.primaryEventsColors[id]?.color ?? "transparent";
}

export const SAMPLE_TAGS = [
  {
    color: "#7986cb", // lavendar
    color_id: "1",
    name: "Sales",
    rules: {
      match_method: "any",
      fields: [
        {
          name: "title",
          condition: "match",
          value: "sales",
        },
      ],
    },
  },
  {
    color: "#33b679", // Sage
    color_id: "2",
    name: "Product",
    rules: {
      match_method: "all",
      fields: [
        {
          name: "title",
          condition: "equal",
          value: "product",
        },
        {
          name: "location",
          condition: "match",
          value: "office",
        },
      ],
    },
  },
  {
    color: "#8e24aa", // Graph
    color_id: "3",
    name: "Marketing",
  },
];

export function summarizeAutoTag({ painterSetting }) {
  // return "Title has growth or title has 1:1 or title has product or title has design, or something else blah blah blah";
  if (isEmptyObjectOrFalsey(getPainterAutoTagRule(painterSetting))) {
    return "";
  }

  const formatWithConjunction = (values, conjunction) => {
    const quotedValues = values.map(value => `"${value}"`);
    if (values.length <= 1) {
      return quotedValues.join("");
    }
    const lastValue = quotedValues.pop();
    return `${quotedValues.join(", ")} ${conjunction} ${lastValue}`;
  };

  const humanReadableValues = ({ values, condition }) => {
    switch (condition) {
      case SMART_TAG_VALUE_RULE.CONTAINS_ANY:
        // spread to ensure that the original values array is not modified by the pop()
        return `has ${formatWithConjunction(values, "or")}`;
      case SMART_TAG_VALUE_RULE.CONTAINS_ALL:
        return `has ${(formatWithConjunction(values, "and"))}`;
      case SMART_TAG_VALUE_RULE.EQUAL:
        // should get get inside here, more of a sanity check
        return `is "${values.join(", ")}"`;
      case SMART_TAG_VALUE_RULE.IN_DOMAIN:
        return `contain domain ${(formatWithConjunction(values, "or"))}`;
      default:
        return "";
    }
  };

  const matchField = getPainterAutoTagMatchType(painterSetting);
  let returnString = "";
  const fields = getPainterAutoTagField(painterSetting);
  fields.forEach((field, index) => {
    const name = getPainterAutoTagRuleFieldName({ painterSetting, index });
    const condition = getPainterAutoTagRuleCondition({ painterSetting, index });
    const value = getPainterAutoTagRuleValue({ painterSetting, index });
    const values = getPainterAutoTagRuleValues({ painterSetting, index });
    if (values?.length > 0) {
      returnString += `${index === 0 ? capitalizeFirstLetter(name) : name} ${humanReadableValues({ values, condition })}${
        isLastIndexOfArray({index, arr: fields})
          ? ""
          : ` ${getSummarizeConjunctions({
              matchField,
              index,
              painterSetting,
            })}`
      }`;
    } else if (value) {
      returnString += `${index === 0 ? capitalizeFirstLetter(name) : name} ${
        condition === PAINTER_RULE_CONDITION_EQUAL ? "is" : "has"
      } ${value}${
        index === fields.length - 1
          ? ""
          : `${
              index === fields.length - 1 ? "," : ""
            } ${getSummarizeConjunctions({
              matchField,
              index,
              painterSetting,
            })}`
      }`;
    }
  });
  return returnString;
}

function getSummarizeConjunctions({ matchField, index, painterSetting }) {
  if (!getPainterAutoTagRuleValue({ painterSetting, index: index + 1 })
    && !getPainterAutoTagRuleValues({ painterSetting, index: index + 1 })
  ) {
    return "";
  }

  return matchField === "any" ? "or " : "and ";
}

export function getPainterAutoTagRule(painterSetting) {
  return painterSetting?.rules ?? {};
}

export function getPainterAutoTagMatchType(painterSetting) {
  return (
    getPainterAutoTagRule(painterSetting).match_method ?? PAINTER_MATCH_ANY
  );
}

export function getPainterAutoTagField(painterSetting) {
  return getPainterAutoTagRule(painterSetting).fields ?? [];
}

function getPainterAutotagAtIndex({ painterSetting, index }) {
  return getPainterAutoTagField(painterSetting)[index] ?? {};
}

function getPainterAutoTagRuleFieldName({ painterSetting, index }) {
  return getPainterAutotagAtIndex({ painterSetting, index }).name ?? "";
}

function getPainterAutoTagRuleCondition({ painterSetting, index }) {
  return getPainterAutotagAtIndex({ painterSetting, index }).condition ?? "";
}

function getPainterAutoTagRuleValues({ painterSetting, index }) {
  return getPainterAutotagAtIndex({ painterSetting, index }).values ?? [];
}

function getPainterAutoTagRuleValue({ painterSetting, index }) {
  return getPainterAutotagAtIndex({ painterSetting, index }).value ?? "";
}

export function isSmartTag({ painterSetting }) {
  return !isEmptyObjectOrFalsey(painterSetting?.rules);
}
