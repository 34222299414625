import { type PromiseExtended } from "dexie";
import _ from "underscore";
import type { GoogleDistroList, GoogleDistroListMember, OutlookDistroList, OutlookDistroListMember } from "../components/queries/distroLists";
import { handleError } from "../services/commonUsefulFunctions";
import db, { type DexieTables, type DexieDistroList, type DexieDistroListMember } from "../services/db";
import { getEventUserEmail } from "../services/eventResourceAccessors";
import { isEmptyArrayOrFalsey, isTypeString } from "../services/typeGuards";
import { uniqueBy } from "./arrayFunctions";
import { getHumanAttendees } from "./eventFunctions";
import { getObjectEmail } from "./objectFunctions";
import { isSameEmail } from "./stringFunctions";

const GOOGLE_GROUP_MEMBER_TYPES = {
  CUSTOMER: "CUSTOMER",
  EXTERNAL: "EXTERNAL",
  GROUP: "GROUP",
  USER: "USER",
};

/**
 * Parse an Outlook distro list member from the backend response into an object for Dexie.
 */
function parseOutlookDistroListMember(outlookDistroListMember: OutlookDistroListMember): DexieDistroListMember {
  return {
    providerId: outlookDistroListMember.provider_id,
    displayName: outlookDistroListMember.display_name,
    email: getObjectEmail(outlookDistroListMember),
  };
}

/**
 * Parse an Outlook distro list from the backend response into an object for Dexie.
 */
function parseOutlookDistroList(outlookDistroList: OutlookDistroList): DexieDistroList {
  return {
    providerId: outlookDistroList.provider_id,
    email: getObjectEmail(outlookDistroList),
    name: outlookDistroList.name,
    description: outlookDistroList.description,
    directMembersCount: null,
    members: outlookDistroList.members.map(parseOutlookDistroListMember),
    subgroups: outlookDistroList.subgroups.map(parseOutlookDistroListMember),
  };
}

function parseGoogleDistroListMember(googleDistroListmember: GoogleDistroListMember): DexieDistroListMember {
  return {
    providerId: googleDistroListmember.google_id,
    displayName: null,
    email: getObjectEmail(googleDistroListmember) ?? "",
  };
}

function parseGoogleDistroList(googleDistroList: GoogleDistroList): DexieDistroList {
  const members = googleDistroList.google_group_members.filter(member => member.member_type !== GOOGLE_GROUP_MEMBER_TYPES.GROUP);
  const subgroups = googleDistroList.google_group_members.filter(member => member.member_type === GOOGLE_GROUP_MEMBER_TYPES.GROUP);

  return {
    providerId: googleDistroList.google_id,
    email: getObjectEmail(googleDistroList),
    name: googleDistroList.name,
    description: googleDistroList.description,
    directMembersCount: googleDistroList.direct_members_count,
    members: members.map(parseGoogleDistroListMember),
    subgroups: subgroups.map(parseGoogleDistroListMember),
  };
}

function parseProviderDistroLists(distroList: GoogleDistroList | OutlookDistroList): DexieDistroList {
  if ("google_id" in distroList) {
    return parseGoogleDistroList(distroList);
  }

  return parseOutlookDistroList(distroList);
}

export async function updateDistroLists({ distroLists, userEmail }: { distroLists: (OutlookDistroList | GoogleDistroList)[], userEmail: string }) {
  try {
    const dexieDistroLists = distroLists.map(parseProviderDistroLists);
    await db.fetch(userEmail)?.distroLists.bulkPut(dexieDistroLists);

    const updatedKeys = dexieDistroLists.map(distroList => distroList.providerId);
    const keysToDelete = await db.fetch(userEmail)?.distroLists.where("providerId").noneOf(updatedKeys).primaryKeys() ?? [];
    db.fetch(userEmail)?.distroLists.bulkDelete(keysToDelete);
  } catch (error) {
    handleError(error);
  }
}

interface QueryDomainDatabasesOptions<TRecord, TTableName extends keyof DexieTables> {
  tableName: TTableName
  query: (table: DexieTables[TTableName]) => PromiseExtended<TRecord[]>
  userEmail: string
}

/**
 * Perform a query across a table from all databases that have the same domain as the user.
 * Results are deduped by the primary key.
 */
async function queryDomainDatabases<TRecord, TTableName extends keyof DexieTables>({
  query,
  tableName,
  userEmail,
}: QueryDomainDatabasesOptions<TRecord, TTableName>): Promise<TRecord[]> {
  const domainDBs = db.fetchByDomain(userEmail);
  if (isEmptyArrayOrFalsey(domainDBs)) {
    return [];
  }

  const queryPromises = domainDBs.map(async (db) => {
    return query(db[tableName]);
  });

  const queryResults = await Promise.all(queryPromises);
  const flattenedResults = queryResults.flat();

  const primaryKeyPath = domainDBs[0][tableName].schema.primKey.keyPath;
  if (isEmptyArrayOrFalsey(primaryKeyPath)) {
    return flattenedResults;
  }

  const getterPath = isTypeString(primaryKeyPath) ? [primaryKeyPath] : primaryKeyPath;
  return uniqueBy(flattenedResults, (record) => _.get(record, getterPath));
}

interface SearchDistroListsOptions {
  string: string
  userEmail: string
}

export async function searchDistroLists({ string, userEmail }: SearchDistroListsOptions) {
  if (!string || !userEmail) {
    return [];
  }
  try {
    return queryDomainDatabases({
      tableName: "distroLists",
      userEmail,
      query: (table) => {
        return table.where("email")
          .startsWithAnyOfIgnoreCase(string)
          .or("name")
          .startsWithAnyOfIgnoreCase(string)
          .or("description")
          .startsWithAnyOfIgnoreCase(string)
          .toArray() ?? [];
      },
    });
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function getMatchingDistroListFromEmails({ emails, userEmail }: { emails: string[], userEmail: string }) {
  if (isEmptyArrayOrFalsey(emails) || !userEmail) {
    return {};
  }
  const dbResponse = await queryDomainDatabases({
    tableName: "distroLists",
    userEmail,
    query: (table) => {
      return table.where("email")
        .anyOfIgnoreCase(emails)
        .toArray();
    },
  });
  if (isEmptyArrayOrFalsey(dbResponse)) {
    return {};
  }
  const dictionary = dbResponse.reduce((acc, item) => {
    const email = getObjectEmail(item);
    if (email) {
      acc[email] = item;
    }
    return acc;
  }, {} as Record<string, DexieDistroList>);
  return dictionary;
}

export async function getSubDistroLists({ event, distroLists }: { event: VimcalEvent, distroLists: Record<string, DexieDistroList> }) {
  const subgroupEmails: Set<string> = new Set();
  Object.values(distroLists).forEach(distroList => {
    distroList.subgroups.forEach(subgroup => {
      subgroupEmails.add(getObjectEmail(subgroup) ?? "");
    });
  });
  const subgroupDistroLists = await getMatchingDistroListFromEmails({
    emails: Array.from(subgroupEmails),
    userEmail: getEventUserEmail(event),
  });
  return subgroupDistroLists;
}

export async function getDistroListsFromEvent(event: VimcalEvent) {
  if (!event) {
    return {};
  }
  const attendees = getHumanAttendees(event);
  const emails = attendees.map((attendee) => getObjectEmail(attendee) ?? "");
  const distroLists = await getMatchingDistroListFromEmails({
    emails,
    userEmail: getEventUserEmail(event),
  });
  const subgroupDistroLists = await getSubDistroLists({ event, distroLists });
  return { ...distroLists, ...subgroupDistroLists };
}

type DistroListDictionary = Record<string, DexieDistroList>

interface DistroListAccessorOptions {
  email: string,
  distroListDictionary: DistroListDictionary
}

export function isDistroListEmail({ email, distroListDictionary }: DistroListAccessorOptions) {
  return Object.keys(distroListDictionary).some(key => isSameEmail(key, email));
}

export function getMatchingDistroList({ email, distroListDictionary }: DistroListAccessorOptions) {
  for (const key of Object.keys(distroListDictionary)) {
    if (isSameEmail(key, email)) {
      return distroListDictionary[key];
    }
  }
}

export function getDistroListName({ email, distroListDictionary }: DistroListAccessorOptions) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  return matchingList?.name ?? "";
}

export function getDistroListDescription({ email, distroListDictionary }: DistroListAccessorOptions) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  return matchingList?.description;
}

export function getDistroDirectMembersCount({ email, distroListDictionary }: DistroListAccessorOptions) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  if (!matchingList) {
    return 0;
  }

  if ("directMembersCount" in matchingList) {
    /**
     * google_groups will now have a property called direct_members_count
     * If this is bigger than 50 we are not returning the members on the google_members property
     */
    return matchingList.directMembersCount ?? 0;
  }

  return 0;
}

export function getDistroListGroupMembers({ email, distroListDictionary }: DistroListAccessorOptions): DexieDistroListMember[] {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  if (!matchingList) {
    return [];
  }
  return matchingList.members;
}

export function getDistroListSubgroups({ email, distroListDictionary }: DistroListAccessorOptions) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  if (!matchingList) {
    return [];
  }

  if ("subgroups" in matchingList) {
    return matchingList.subgroups;
  }

  return [];
}

export function getDistroListGroupMembersAndSubgroups({ email, distroListDictionary }: DistroListAccessorOptions) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  if (!matchingList) {
    return [];
  }

  const members = getDistroListGroupMembers({ email, distroListDictionary });
  const subgroups = getDistroListSubgroups({ email, distroListDictionary });
  return [...members, ...subgroups];
}

export function isDistroListTooBigForExpansion({
  email,
  distroListDictionary,
}: DistroListAccessorOptions) {
  const LIMIT = 50;
  return (
    getDistroDirectMembersCount({ email, distroListDictionary }) >= LIMIT ||
    getDistroListGroupMembers({ email, distroListDictionary })?.length >= LIMIT
  );
}
