import { handleError } from "../services/commonUsefulFunctions";
import { useAllCalendars, useAllLoggedInUsers, useMasterAccount } from "../services/stores/SharedAccountData";

export function getAllCalendarsFromStore() {
  try {
    if (!useAllCalendars?.getState) {
      return null;
    }
    return useAllCalendars.getState()?.allCalendars;
  } catch (error) {
    handleError(error);
    return null;
  }
}

export function getMasterAccountFromStore() {
  try {
    if (!useMasterAccount?.getState) {
      return null;
    }
    return useMasterAccount.getState()?.masterAccount;
  } catch (error) {
    handleError(error);
    return null;
  }
}

export function getAllLoggedInUsersFromStore() {
  try {
    if (!useAllLoggedInUsers?.getState) {
      return null;
    }
    return useAllLoggedInUsers.getState()?.allLoggedInUsers;
  } catch (error) {
    handleError(error);
    return null;
  }
}
