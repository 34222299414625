import {
  differenceInDays,
  differenceInMinutes,
  formatISO,
  parseISO,
} from "date-fns";
import {
  convertTrueFalseStringIntoValue,
  handleError,
  isInt,
  isValidJSDate,
  isValidTimeZone,
  localData,
} from "../services/commonUsefulFunctions";
import {
  ISO_DATE_FORMAT,
  PERSONAL_LINK_LOCAL_DATA,
} from "../services/globalVariables";
import { safeJSONParse } from "./jsonFunctions";
import { isNullOrUndefined } from "../services/typeGuards";

export const CURRENT_USER_EMAIL = "CURRENT_USER_EMAIL"; // for fetcher when redux doesn't synchronously update variables
const LOCAL_REFERRAL_CODE_NAME = "referralCode";

export const LOCAL_DATA_ACTION = {
  SET: "set",
  GET: "get",
  DELETE: "delete",
  CLEAR: "clear",
  HAS: "has",
};

export function setCurrentUserEmail(email) {
  if (!sessionStorage?.setItem || !email) {
    localData(LOCAL_DATA_ACTION.SET, CURRENT_USER_EMAIL, email);
    return;
  }

  // Use session storage since session storage is stored on a per tab basis
  sessionStorage.setItem(CURRENT_USER_EMAIL, email);
  localData(LOCAL_DATA_ACTION.SET, CURRENT_USER_EMAIL, email);
}

export function getCurrentUserEmail() {
  if (!sessionStorage?.setItem) {
    return localData(LOCAL_DATA_ACTION.GET, CURRENT_USER_EMAIL);
  }

  const currentSessionUserEmail = sessionStorage.getItem(CURRENT_USER_EMAIL);
  if (currentSessionUserEmail) {
    return currentSessionUserEmail;
  }

  // this should only happen when user opens up new tab
  const defaultCurrentUserEmail = localData(
    LOCAL_DATA_ACTION.GET,
    CURRENT_USER_EMAIL
  );
  sessionStorage.setItem(CURRENT_USER_EMAIL, defaultCurrentUserEmail);

  return defaultCurrentUserEmail;
}

function getTemplateKey(email) {
  return "templates_" + email;
}

export function saveUserTemplates(email, templates) {
  if (!email || !templates) {
    return;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    getTemplateKey(email),
    JSON.stringify(templates)
  );
}

export function getUserTemplates(email) {
  if (!email) {
    return;
  }

  const unformattedTemplates = localData(
    LOCAL_DATA_ACTION.GET,
    getTemplateKey(email)
  );
  if (!unformattedTemplates) {
    return;
  }

  return safeJSONParse(unformattedTemplates);
}

export function getSavedPersonalLinks(user) {
  if (!user) {
    return [];
  }

  const item = localData(
    LOCAL_DATA_ACTION.GET,
    `${PERSONAL_LINK_LOCAL_DATA}_${user.email}`
  );
  if (!item) {
    return [];
  }

  return safeJSONParse(item) ?? [];
}

export function savePersonalLinksIntoLocalData(updatedPersonalLinks, user) {
  if (!user?.email) {
    return;
  }

  localData(
    LOCAL_DATA_ACTION.SET,
    `${PERSONAL_LINK_LOCAL_DATA}_${user?.email}`,
    JSON.stringify(updatedPersonalLinks)
  );
}

export function getStoredReferralCode() {
  return localData(LOCAL_DATA_ACTION.GET, LOCAL_REFERRAL_CODE_NAME);
}

export function setStoredReferralCode(referralCode) {
  if (!referralCode) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, LOCAL_REFERRAL_CODE_NAME, referralCode);
  return;
}

export function removeStoredReferralCode() {
  localData(LOCAL_DATA_ACTION.DELETE, LOCAL_REFERRAL_CODE_NAME);
  return;
}

function getComponentResetKey(componentName) {
  const PAGE_HAS_BEEN_FORCED_REFRESHED = "page-has-been-forced-refreshed";
  return `${PAGE_HAS_BEEN_FORCED_REFRESHED}_${componentName}`;
}

export function hasComponentBeenForceRefreshed(componentName) {
  return safeJSONParse(
    localData(LOCAL_DATA_ACTION.GET, getComponentResetKey(componentName)) ||
      "false"
  );
}

export function setHasComponentBeenForceRefreshed(componentName) {
  localData(LOCAL_DATA_ACTION.SET, getComponentResetKey(componentName), "true");
}

export function resetHasComponentBeenForceRefreshed(componentName) {
  localData(
    LOCAL_DATA_ACTION.SET,
    getComponentResetKey(componentName),
    "false"
  );
}

const TAB_ID = "tabID";
export function saveTabIDIntoLocalData(tabID) {
  localData(LOCAL_DATA_ACTION.SET, TAB_ID, tabID);
}

export function getTabIDFromLocalData() {
  return localData(LOCAL_DATA_ACTION.GET, TAB_ID);
}

const LAST_REFRESH_FROM_INDEX_DB_ERROR = "indexDB_refresh";
export function saveLastRefreshFromIndexDBErrorTime() {
  localData(
    LOCAL_DATA_ACTION.SET,
    LAST_REFRESH_FROM_INDEX_DB_ERROR,
    new Date().toISOString()
  );
}

function getLastRefreshFromIndexDBErrorTime() {
  const lastTime = localData(
    LOCAL_DATA_ACTION.GET,
    LAST_REFRESH_FROM_INDEX_DB_ERROR
  );
  if (!lastTime) {
    return;
  }

  return parseISO(lastTime);
}

export function shouldRefreshFromIndexDBError() {
  const lastTime = getLastRefreshFromIndexDBErrorTime();
  if (!lastTime) {
    return true;
  }

  const currentTime = new Date();
  const minutesDifference = differenceInMinutes(currentTime, lastTime);

  return minutesDifference > 15;
}

const GROUP_VOTE_DURATION_ON_DRAG = "groupVoteDurationOnDrag";
export function setGroupVoteDurationOnDrag(amount) {
  localData(LOCAL_DATA_ACTION.SET, GROUP_VOTE_DURATION_ON_DRAG, amount);
}

export function getGroupVoteDurationOnDrag() {
  return localData(LOCAL_DATA_ACTION.GET, GROUP_VOTE_DURATION_ON_DRAG);
}

// this is the default duration that is used when a user clicks on a group vote slot
const GROUP_VOTE_DURATION_ON_CLICK = "groupVoteDurationOnClick";
export function getDefaultGroupVoteClickDuration() {
  try {
    const minutes = parseInt(localData(LOCAL_DATA_ACTION.GET, GROUP_VOTE_DURATION_ON_CLICK));
    if (minutes && isInt(minutes)) {
      return minutes;
    }
    return null;
  } catch (error) {
    handleError(error);
    return null;
  }
}
export function setDefaultGroupVoteClickDuration(minutes) {
  if (!isInt(minutes)) {
    return;
  }
  localData(LOCAL_DATA_ACTION.SET, GROUP_VOTE_DURATION_ON_CLICK, minutes);
}
export function removeDefaultGroupVoteClickDuration() {
  localData(LOCAL_DATA_ACTION.DELETE, GROUP_VOTE_DURATION_ON_CLICK);
}
export function removeGroupVoteDurationOnDrag() {
  localData(LOCAL_DATA_ACTION.DELETE, GROUP_VOTE_DURATION_ON_DRAG);
}

const LAST_BACKEND_RESPONSE_VERSION = "backendAppVersion";
export function getLastBackendResponseVersion() {
  return localData(LOCAL_DATA_ACTION.GET, LAST_BACKEND_RESPONSE_VERSION);
}

export function setLastBackendResponseVersion(version) {
  localData(LOCAL_DATA_ACTION.SET, LAST_BACKEND_RESPONSE_VERSION, version);
}

const LOCATION_TIMEZONE = "locationTimeZone";
export function saveLocationTimeZone(tz) {
  localData(LOCAL_DATA_ACTION.SET, LOCATION_TIMEZONE, tz);
}
export function getSavedLocationTimeZone() {
  const savedLocationTimeZone = localData(
    LOCAL_DATA_ACTION.GET,
    LOCATION_TIMEZONE
  );
  if (isValidTimeZone(savedLocationTimeZone)) {
    return savedLocationTimeZone;
  }
}

const GLOBAL_JOINED_KEY = "shouldEnableGlobalJoinMeeting";
export function getIsGlobalJoinEnabled() {
  const storedValue = localData(LOCAL_DATA_ACTION.GET, GLOBAL_JOINED_KEY);
  if (isNullOrUndefined(storedValue)) {
    return true;
  }
  return convertTrueFalseStringIntoValue(storedValue);
}

export function setInStorageIsGlobalJoinEnabled(value) {
  localData(LOCAL_DATA_ACTION.SET, GLOBAL_JOINED_KEY, value);
}

const OPEN_ON_LOGIN_KEY = "openAtLogin";
export function shouldOpenOnLogin() {
  return convertTrueFalseStringIntoValue(
    localData(LOCAL_DATA_ACTION.GET, OPEN_ON_LOGIN_KEY)
  );
}

export function setShouldOpenOnLogin(val) {
  localData(LOCAL_DATA_ACTION.SET, OPEN_ON_LOGIN_KEY, val);
}

const LAST_SELECTED_DATE = "lastSelectedDate";
export function setLastSelectedDate(date) {
  if (!isValidJSDate(date)) {
    return;
  }
  localData(
    LOCAL_DATA_ACTION.SET,
    LAST_SELECTED_DATE,
    formatISO(date, ISO_DATE_FORMAT)
  );
}

export function getLastSelectedDate() {
  const savedDate = localData(LOCAL_DATA_ACTION.GET, LAST_SELECTED_DATE);
  if (!savedDate) {
    return null;
  }
  try {
    return parseISO(savedDate);
  } catch (e) {
    handleError(e);
    return null;
  }
}

const SSO_LOGIN_CODE = "ssoLoginToken";
export function saveSSOLoginToken(loginToken) {
  if (!loginToken) {
    return;
  }

  localData(LOCAL_DATA_ACTION.SET, SSO_LOGIN_CODE, loginToken);
}

export function getSavedSSOLoginToken() {
  return localData(LOCAL_DATA_ACTION.GET, SSO_LOGIN_CODE);
}

export function removeSavedSSOLoginToken() {
  localData(LOCAL_DATA_ACTION.DELETE, SSO_LOGIN_CODE);
}

// this is only for accounts that should be auto logged out after a certain amount of time
function getAutoLogoutLoginDateKey(email) {
  return `loginTime_${email}`;
}

export function setAutoLogoutLoginDate(email) {
  localData(
    LOCAL_DATA_ACTION.SET,
    getAutoLogoutLoginDateKey(email),
    new Date().toISOString()
  );
}

export function getAutoLogoutLoginDate(email) {
  try {
    const loginTime = localData(
      LOCAL_DATA_ACTION.GET,
      getAutoLogoutLoginDateKey(email)
    );
    if (!loginTime) {
      return;
    }
    return parseISO(loginTime);
  } catch (error) {
    handleError(error);
  }
}

const APP_VERSION_KEY = "appVersion";
export function getLocalDataAppVersion() {
  return localData(LOCAL_DATA_ACTION.GET, APP_VERSION_KEY);
}

export function setLocalDataAppVersion(version) {
  if (!version) {
    return;
  }
  localData(LOCAL_DATA_ACTION.SET, APP_VERSION_KEY, version);
}
